// Cache manipulation toolbox
// Provides easy access to the cache

import store from '../../redux/store'
import { get_settings } from '../api/Users';

export const expireSession = () => {
    store.dispatch({type:'KEYCLOAK', payload: {user: false, token: false}});
}

export const get_user_settings_from_database_and_save_user_to_cache = (keycloak, user) => {

    return new Promise((resolve, reject) => {
        get_settings(user.sub)
        .then(data => {
            if (data) {
                let newUser = {
                    ...user,
                    ...data?.data
                }
                store.dispatch({type:'KEYCLOAK', payload: {user: newUser, token: keycloak.token}});
                resolve(newUser);
            }
            else resolve(false);
            return;
        })
        .catch(err => {
            resolve(false);
            return;
        })
    })
}

export const update_user_in_cache = (user) => {
   
    let newstate = {
            token: store.getState()?.user?.token,
            user: {...store.getState()?.user?.user, ...user}
    
    }
    store.dispatch({type: 'USER_UPDATE', payload: newstate});
}