import axios from "axios"
import { config } from "../../config"

const uploadPic = (files, type, userId) => {

  let form = new window.FormData(files[0]);
  form.append('file', files);
  form.append('type', type);
  form.append('userId', userId);

  return new Promise((resolve, reject) => {

      axios.post(`${config.ERISED_BACKEND_URL}/media/users/upload`, form, {
          // headers: {
          //   "Content-Type": "multipart/form-data",
          // },
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          resolve(false)
        });
  })
}
export {uploadPic};