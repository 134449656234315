import axios from 'axios';
import store from '../redux/store';
import i18n from '../localization/i18next';

const clients = require('../config/index').config
const client = process.env.REACT_APP_CLIENT;
const lang = i18n.language ? i18n.language : 'el';

export function jwtReqInterceptor() {

    axios.interceptors.request.use(request => {

        const token = store.getState()?.user?.token;

        request.headers.Authorization = `${token}`;

        return request;
    });
}

export function jwtResInterceptor() {
    
    axios.interceptors.response.use(response => {

        if(response?.data?.code == 404) {}
        else return response;

    }, err => {
        if (err?.response?.data?.code == 403) {
           window.location.assign(`${clients[client]}/${lang}/access-denied`)
        }
        else if (err?.response?.data?.code == 401) {
            window.location.assign(`${clients[client]}/${lang}/auth`)
        } 
        // else if (err?.response?.data?.code == 600) {
        // }
        // else if (!err?.response?.data?.code) {
        //     /// no code means that the communication with backend was not possible at all
        //     // here we should navigate to the page described in DOIT-532’
        //     if (!window.location.pathname.includes('service-unavailable')) {
        //         window.location.assign(`${clients[client]}/${lang}/service-unavailable`)
        //     }
        // }
        //Correct this pleeeaseee
        else if(!err.response?.data?.code && err.response?.data?.msg==="410"){
            if (!window.location.pathname.includes('verify-account')) {
                window.location.assign(`${clients[client]}/${lang}/verify-account`)
            }
        }
    })
}