import { Actions_, AlertContext, Alert_, Builder_, Form, Input_, Input_Phone_Register, Main_, Main_Plain, PageAlwaysVisible, PageDesktopOnly, PageForty, PageIllu, PageTitle, Single_, Steps_Actions } from 'monica-alexandria';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../services/authentication/Guard';
import i18n from '../../localization/i18next';
import { useTranslation } from 'react-i18next';
import store from '../../redux/store';
import q from 'query-string'
import { reSendVerificationCode, sendVerificationCode, verifyEmailCode } from '../../services/api/Users';
import { validateOTP } from '../../helpers/validators';
import Countdown_ from './components/Countdown_';
import { Helmet } from 'react-helmet';

const protocol = process.env.REACT_APP_AUTH_PROTOCOL;
const url = process.env.REACT_APP_AUTH_HOST;
const port = process.env.REACT_APP_AUTH_PORT;
const realm = process.env.REACT_APP_REALM;
const lang = i18n.language ? i18n.language : 'en';

export default function VERIFY_EMAIL() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const auth = useContext(AuthContext);
    const user = store.getState().user
    const params = q.parse(window.location.search);
    const otpInput = useRef(''); 
    const [messages, setMessages] = useState({
        errors: [], 
        helpers: [],
        successes: [],
    });
    const [code, setCode] = useState();
    const [shouldDisplayLoader, setShouldDisplayLoader] = useState(false);
    const [shouldDisplayCountdown, setShouldDisplayCountdown] = useState(false);
    const [currentCountdown, setCurrentCountdown] = useState("");

    const { alerts, setAlerts } = useContext(AlertContext);

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    //otp validator
    const handleChange = (e) => {
        let dataOTP = validateOTP(e.target.value, t);
        setCode(e.target.value)
       // let dataOTP = []
        setMessages({   
            ...messages,
            helpers: dataOTP?.helperOTP,
            successes: dataOTP?.successOTP,
        });
        // setPhoneInput(e.target.value)
        otpInput.current = e.target.value;
    };


    const send_Verification_Code = (e) => {
           //check phone on sub
        let erroremptyinput = t('This field should not be empty');
        let otpErrors = [];

        e.preventDefault();
        //if we have helpers they become errors
        if (messages.helpers?.length !== 0) otpErrors = messages.helpers;
        //empty input
        if (otpInput.current?.length == 0) otpErrors = [erroremptyinput];

        setMessages({ ...messages, errors: otpErrors });

        //clean inputs if there is an error
        if (otpErrors?.length != 0) {
            // setPhoneInput("");
            otpInput.current = '';
        }

        if (otpErrors?.length) {
            alertMessage((t('Invalid OTP format. Please check your input and try again.')), 'Error');
   
        } else {
            verifyEmailCode(user.user?.userId, code, false)
            .then((res) => {
                if ( res.verified ) window.location.assign(params?.redirectUrl)
                else alertMessage((t('Wrong OTP. Please check your input and try again.')), 'Error');
            })
            .catch((err) => {})
        }
    }

    const re_send_Verification_Code = () => {
        setShouldDisplayLoader(true);
        reSendVerificationCode(user.user?.userId, user.user?.username, lang)
        .then((res) => {
            console.log('reees',res);
            const secondsPassed = res?.data?.secondsPassed;
            const hasResend = res?.data?.shouldResend; 
            const secondsRemaining = 30 - secondsPassed;
            setShouldDisplayCountdown(hasResend ? 30 : secondsRemaining);
            setShouldDisplayLoader(false);
        }).catch(err=>{
            setShouldDisplayLoader(false);
        })
    }


    useEffect(() => {
        const send_Verification_Code = () => {
            sendVerificationCode(user.user?.userId, user.user?.username, lang)
            .then((res) => {}).catch(err=>{})
        }

        send_Verification_Code()
    },[])


    useEffect(() => {
        if ( currentCountdown === "0:00" ) setShouldDisplayCountdown(false);
    },[currentCountdown])

    useEffect(() => {
        console.log("email_verified", user)
        if(user.user?.email_verified) window.location.replace(params?.redirectUrl)
    },[user.user?.email_verified])
    //dummy logic = email_verified in user cahce is from token
    if(user.user?.email_verified) window.location.replace(params?.redirectUrl)
    else{
        return (
            <PageForty>
                <Helmet>
                    <title>Doitforme | Email verification</title>
                </Helmet>
            <PageAlwaysVisible>
            <Alert_ messages={alerts} />
                <PageTitle>
                    <h1>{t('Verify your email')}</h1>
                </PageTitle>
                <Form>
                    <Builder_
                        noPads
                        title={t('Enter otp code')}
                        last
                    >
                        <Input_
                            placeholder={t('OTP')}
                            required
                            onChange={handleChange}
                            errors={messages?.errors}
                            helpers={messages?.helpers}
                            successes={messages?.successes}
                            value={otpInput?.current}
                            defaultValue={otpInput?.current}
                        />
                    </Builder_>
                    {/* <Steps_Actions
                        text_Main={t('Next')}
                        icon_Main="Next"
                    /> */}
                    <Actions_ end>
                        {/* { shouldDisplayLoader ? <p>Sending...</p> : null }  */}
                        { shouldDisplayCountdown ? <Countdown_ seconds={shouldDisplayCountdown} currentCountdown={(cd) => setCurrentCountdown(cd)}/> : null } 
                        { !shouldDisplayCountdown && !shouldDisplayLoader ? 
                        <Main_Plain 
                            text={t('Resend code')}
                            onClick={() => re_send_Verification_Code()}
                            noPadding
                        /> : null }
                        <Main_
                            text={t("Next")}
                            iconRight="Next"
                            onClick={send_Verification_Code}
                        />
                    </Actions_>
                </Form>
            </PageAlwaysVisible>
            <PageDesktopOnly>
                <PageIllu>
                    <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/erised/Password.svg" />
                </PageIllu>
            </PageDesktopOnly>
        </PageForty>
        );
    }
}
