import {
    AlertContext,
    Actions_,
    Alert_,
    Builder_,
    Dropdown_,
    Form,
    Grey_Link,
    IconSvg,
    Input_,
    Input_Phone,
    Input_Phone_Register,
    Main_,
    PageAlwaysVisible,
    PageTitle,
    PageToolsSixty,
    Slider_,
    PageFull,
    PageSixty,
    Main_Plain,
} from 'monica-alexandria';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Header_ from '../../components/Header_';
import { uploadPic } from '../../services/api/Upload';
import Aside_ from '../../components/navigation/Aside_';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { validateEmail, validateOTP, validatePhone } from '../../helpers/validators';
import { edit_settings, get_settings, reSendVerificationCode, sendVerificationCode, verifyEmailCode } from '../../services/api/Users';
import store from '../../redux/store';
import { update_user_in_cache } from '../../services/authentication/SessionService';
import _ from 'lodash';
import Upload_ from '../../components/upload/Upload_';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Input_Email } from './components/Input_Email';
import Countdown_ from '../verifyEmail/components/Countdown_';
const clients = require('../../config/index').config;

const minioUrl = clients['minio'];

const Account = styled(PageSixty)`
    .FormList{
        
        li{
            align-items: flex-start !important;

            svg{
                margin-top: 4px;
            }
        }
    }
`

const AccountEmail= styled.div`
    display: flex;
    gap: .5rem;
    align-items: center;
    transition: opacity .5s;

    h6{
        color: ${p => p.status ? "var(--success)" : "var(--warning)"};

        span{
            color: var(--main) !important;
            cursor: pointer;

            &:hover{
                opacity: ${p => p.status ? "1" : ".5"};
                transition: opacity .5s;
            }
        }
    }

    svg{
        fill: ${p => p.status ? "var(--success)" : "var(--warning)"} !important;
        width: 1.2rem !important;
        margin-bottom: 2px;
    }
`

export default function ACCOUNT() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const otpInput = useRef(''); 
    const lang = store.getState().selectedLanguage
    const userId = store.getState().user?.user?.sub;
    const [emailToVerify, setEmailToVerify] = useState('');
    const [userInfo, setUserInfo] = useState({
        userId: userId,
        firstname: '',
        lastname: '',
        gender: 'Gender',
        phone: '',
        emailList: [],
        phoneList: [],
        verified: [],
    });
    const [currentEmail, setCurrentEmail] = useState('');
    const [currentPhone, setCurrentPhone] = useState('');
    const [slider, toggleSlider] = useState(false);
    const [verified, setVerified] = useState(false)
    const { alerts, setAlerts } = useContext(AlertContext);
    const [messages, setMessages] = useState({
        errorsPhone: [],
        helpersPhone: [],
        successesPhone: [],
        errorsEmail: [],
        helpersEmail: [],
        successesEmail: [],
    });

    const [profilePic, setProfilePic] = useState(false);
    const [coverPic, setCoverPic] = useState(false);
    const [filesCover, setFilesCover] = useState([]);
    const [uploading, isUploading] = useState(false);
    const [code, setCode] = useState();
    const [shouldDisplayLoader, setShouldDisplayLoader] = useState(false);
    const [shouldDisplayCountdown, setShouldDisplayCountdown] = useState(false);
    const [currentCountdown, setCurrentCountdown] = useState("");

    const saveSettings = () => {
        
        let updatedUserInfo = JSON.parse(JSON.stringify(userInfo));
        delete updatedUserInfo.email;
        delete updatedUserInfo.phone;

        updatedUserInfo.emails = userInfo.emailList;
        updatedUserInfo.phones = userInfo.phoneList;

       // updateAccount(updatedUserInfo);
        editSettings(updatedUserInfo)
    };


    const updateAccount = (newDataInfo) =>{

        let updatedUserInfo = JSON.parse(JSON.stringify(newDataInfo));
        delete updatedUserInfo.email;
        delete updatedUserInfo.phone;

        updatedUserInfo.phones = newDataInfo.phoneList;

        // updatedUserInfo.emails = _.map(newDataInfo.emailList, (email) => {
        //     return { verified: email?.verified, email: email?.email };
        // });
        updatedUserInfo.emails = newDataInfo?.emailList;


        editSettings(updatedUserInfo)
    
    }


    const editSettings= (newUserInfo) => {
        edit_settings(newUserInfo)
        .then((data) => {
            if (data) {
                update_user_in_cache(newUserInfo);
                alertMessage((t('Your changes have been saved!')), 'Success');
            }
        })
        .catch((err) => {
            alertMessage(
                (t('There was an error, please try again later')),
                'Error'
            );
        });
        
    }

    const getSettings = () => {
        get_settings(userId)
            .then((data) => {
                console.log('DTAAAAA', data);
                let emailList = [];
                let verified = data?.data?.emails;
                if (
                    Array.isArray(data?.data?.emails) &&
                    typeof data?.data?.emails[0] === 'object'
                ) {
                    data?.data?.emails?.forEach((element) => {
                        emailList.push(element);
                    });

                } else emailList = data?.data?.emails ? [...data?.data?.emails, data?.data?.username] : [];

               // let phones = [...data?.data?.phones, data?.data?.username];
                setUserInfo({
                    ...userInfo,
                    firstname: data?.data?.firstname
                        ? data?.data?.firstname
                        : '',
                    lastname: data?.data?.lastname ? data?.data?.lastname : '',
                    gender: data?.data?.gender ? data?.data?.gender : 'Other',
                    username: data?.data?.username ? data.data.username : '',
                    phoneList: data?.data?.phones,
                    emailList: emailList,
                    verified: verified,
                });
            })
            .catch((err) =>{});
    };

    const validatePhoneFormat = (e) => {
        let dataPhone = validatePhone(e.target.value, t);

        setMessages({
            ...messages,
            helpersPhone: dataPhone.helper,
            successesPhone: dataPhone.success,
        });
        setCurrentPhone(e.target.value);
    };

    const validateEmailFormat = (e) => {
        let dataEmail = validateEmail(e.target.value, t);

        setMessages({
            ...messages,
            helpersEmail: dataEmail.helperemail,
            successesEmail: dataEmail.successemail,
        });
        setCurrentEmail(e.target.value);
    };




    function addPhone() {
        let phoneErrors = [];
        const phoneExists = userInfo?.phoneList?.includes(currentPhone);

        if (messages.helpersPhone?.length !== 0)
            phoneErrors = messages.helpersPhone;

        setMessages({ ...messages, errorsPhone: phoneErrors });

        if (phoneErrors.length != 0) {
            setCurrentPhone('');
            alertMessage(
                (t('Invalid phone, please check your input and try again...')),
                'Error'
            );
        } else if (phoneExists) {
            setCurrentPhone('');
            alertMessage(
                t(
                    'Phone already exists, please check your input and try again...'
                ),
                'Error'
            );
            setMessages({
                ...messages,
                successesPhone: [],
                helpersPhone: [],
                errorsPhone: [],
            });
        } else {
            setUserInfo({
                ...userInfo,
                phoneList: [...userInfo.phoneList, currentPhone],
            });
            setCurrentPhone('');
            setMessages({
                ...messages,
                successesPhone: [],
                helpersPhone: [],
                errorsPhone: [],
            });
            alertMessage(
                (t('Your phone has been successfully added ')),
                'Success'
            );
        }
    }

    function addEmail() {
        let emailErrors = [];
        const emailExists = userInfo?.emailList?.includes(currentEmail);

        if (messages.helpersEmail?.length !== 0)
            emailErrors = messages.helpersEmail;
        setMessages({ ...messages, errorsEmail: emailErrors });

        if (emailErrors.length != 0) {
            setCurrentEmail('');
            alertMessage(
                (t('Invalid email, please check your input and try again...')),
                'Error'
            );
        } else if (emailExists) {
            setCurrentEmail('');
            setMessages({
                ...messages,
                successesEmail: [],
                helpersEmail: [],
                errorsEmail: [],
            });
            alertMessage(
                t(
                    'Email already exists, please check your input and try again...'
                ),
                'Error'
            );
        } else {
            const newVerification = { email: currentEmail, verified: false }; 
            setUserInfo(prevUserInfo => {
                return {
                    ...prevUserInfo,
                    emailList: [...prevUserInfo.emailList, newVerification],
                    // verified: prevUserInfo.verified ? [...prevUserInfo.verified, newVerification] : [newVerification],
                };
            });
    
            setCurrentEmail('');
            setMessages({
                ...messages,
                successesEmail: [],
                helpersEmail: [],
                errorsEmail: [],
            });
            alertMessage(
                (t('Your email has been successfully added ')),
                'Success'
            );
        }
    }

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
        //     setTimeout(() => {
        //     setAlerts([]);
        // }, 8000);
    };

    const dltEmail = (email) => {
        // let newVerified = JSON.parse(JSON.stringify(userInfo.verified));
        // newVerified.splice(1, 1);

        setUserInfo((prevState) => {
            return {
                ...prevState,
                emailList: prevState.emailList.filter((_, i) => i !== email),
              //  verified: newVerified,
            };
        });
    };

    const dltPhone = (phone) => {
        setUserInfo((prevState) => {
            return {
                ...prevState,
                phoneList: prevState.phoneList.filter((_, i) => i !== phone),
            };
        });
    };

    const upload_pic = (files, type) => {
        if (files[0]) {
            isUploading(true);
            uploadPic(files[0], type, userId)
                .then((res) => isUploading(false))
                .catch((err) => isUploading(false));
        }
    };
    
    //otp validator
    const handleChange = (e) => {
        let dataOTP = validateOTP(e.target.value, t);
        setCode(e.target.value)
       // let dataOTP = []
        setMessages({   
            ...messages,
            helpers: dataOTP?.helperOTP,
            successes: dataOTP?.successOTP,
        });
        // setPhoneInput(e.target.value)
        otpInput.current = e.target.value;
    };

    const verify_code = (e) => {
    //check phone on sub
     let erroremptyinput = t('This field should not be empty');
     let otpErrors = [];

     //if we have helpers they become errors
     if (messages.helpers?.length !== 0) otpErrors = messages.helpers;
     //empty input
     if (otpInput.current?.length == 0) otpErrors = [erroremptyinput];

     setMessages({ ...messages, errors: otpErrors });

     //clean inputs if there is an error
     if (otpErrors?.length != 0) {
         // setPhoneInput("");
         otpInput.current = '';
     }

     if (otpErrors?.length) {
         alertMessage((t('Invalid OTP format. Please check your input and try again.')), 'Error');

     } else {
         verifyEmailCode(userId, code, emailToVerify)
         .then((res) => {
            console.log('RESSSS', res);
             if ( res.verified ) {
                toggleSlider(false);
                const updatedUserInfo = {
                    ...userInfo,
                   // emailList: [...userInfo.emailList, currentEmail],
                   emailList: userInfo?.emailList?.map(item => {
                        if (item.email === emailToVerify) {
                            return { ...item, verified: true };
                        }
                        return item;
                    })
                };
                setUserInfo(updatedUserInfo);
                

                //saveSettings();
             updateAccount(updatedUserInfo);

                alertMessage(
                    (t('Your email is verified ')),
                    'Success'
                );                
             }
             else alertMessage((t('Wrong OTP. Please check your input and try again.')), 'Error');
         })
         .catch((err) => {})
     }
 }

    const verify_new_email = (email) => {
        console.log("email", email)
        setEmailToVerify(email)
        toggleSlider(true)
        sendVerificationCode(userId, email, lang)
        .then((res) => {}).catch(err=>{})
    };

    const resend_verification_code = () => {
        setShouldDisplayLoader(true);
        reSendVerificationCode(userId, emailToVerify, lang)
        .then((res) => {
            console.log('reees',res);
            const secondsPassed = res?.data?.secondsPassed;
            const hasResend = res?.data?.shouldResend; 
            const secondsRemaining = 30 - secondsPassed;
            setShouldDisplayCountdown(hasResend ? 30 : secondsRemaining);
            setShouldDisplayLoader(false);
        }).catch(err=>{
            setShouldDisplayLoader(false);
        })
    }

    useEffect(() => {
        getSettings();
    }, []);

    useEffect(() => {
        if ( currentCountdown === "0:00" ) setShouldDisplayCountdown(false);
    },[currentCountdown])

    console.log('userInfo', userInfo);

    return (
        <Account>
            <Helmet>
                <title>Doitforme | User account</title>
            </Helmet>
            <Alert_
                messages= {alerts}
             />   
           { slider && 
                <Slider_ title={t("Email verification")}  
                    text={t("Verify")} 
                    iconGreyLeft="Xicon" 
                    grey={t("Cancel")} 
                    onGrey={() => toggleSlider(false)} 
                    onClick={(e) => verify_code(e)} 
                    onClose = {() => toggleSlider(false)}
                >
                <Builder_ title={t("Enter the OTP you recieved in your email")}>
                    <Input_ 
                        placeholder={t("One-time-password")}
                        required 
                        errors={messages?.errors}
                        helpers={messages?.helpers}
                        successes={messages?.successes}
                        value={otpInput?.current} 
                        defaultValue={otpInput?.current}
                        onChange={handleChange}
                    />
                </Builder_>
                <Actions_ end>
                    { shouldDisplayCountdown ? 
                        <Countdown_ seconds={shouldDisplayCountdown} currentCountdown={(cd) => setCurrentCountdown(cd)}/> 
                    : null}
                    { !shouldDisplayCountdown && !shouldDisplayLoader ? 
                        <Main_Plain 
                            text={t('Resend code')}
                            onClick={() => resend_verification_code()}
                            noPadding
                        /> 
                        : null}
                </Actions_>
            </Slider_>}
            <PageAlwaysVisible>
                <PageTitle>
                    <Grey_Link
                        iconLeft="Back"
                        onClick={() => navigate(`/${lang}/settings`)}
                        noPadding
                    />
                    <h1>{'My account'}</h1>
                </PageTitle>
                <Header_ uploading={uploading} />
                <Form >
                    <Builder_ noPads title={t('Edit your first name')}>
                        <Input_
                            placeholder={t('First name')}
                            value={userInfo.firstname}
                            required
                            onChange={(event) =>
                                setUserInfo({
                                    ...userInfo,
                                    firstname: event.target.value,
                                })
                            }
                        />
                    </Builder_>
                    <Builder_ title={t('Edit your last name')} noPads>
                        <Input_
                            placeholder={t('Last name')}
                            value={userInfo.lastname}
                            required
                            onChange={(event) =>
                                setUserInfo({
                                    ...userInfo,
                                    lastname: event.target.value,
                                })
                            }
                        />
                    </Builder_>
                    <Builder_ title={t('Edit your gender')} noPads>
                        <Dropdown_>
                            <select
                                onChange={(event) =>
                                    setUserInfo({
                                        ...userInfo,
                                        gender: event.target.value,
                                    })
                                }
                                value={userInfo.gender}
                            >
                                <option>{t("Gender")}</option>
                                <option>{t('Male')}</option>
                                <option>{'Female'}</option>
                                <option>{t('Other')}</option>
                            </select>
                        </Dropdown_>
                    </Builder_>
                    <Builder_ title={t('Edit your email addresses')} noPads>
                        <Input_Email
                            placeholder={t('Email address')}
                            defaultListItem={userInfo?.username}
                            list = {userInfo?.emailList || []}
                            icon_ListItem="Email"
                            errors={messages.errorsEmail}
                            helpers={messages.helpersEmail}
                            successes={messages.successesEmail}
                            onChange={validateEmailFormat}
                            value={currentEmail}
                            onAdd={addEmail}
                            onDelete={(i) => dltEmail(i)}
                            add={t('Add')}
                            onVerify = {(email)=> verify_new_email(email)}
                        />
                    </Builder_>
                    <Builder_ title={t('Edit your phone numbers')} noPads>
                        <Input_Phone_Register
                            placeholder={t('Phone number')}
                            list = {userInfo?.phoneList || []}
                            listIcon="Phone"
                            errors={messages.errorsPhone}
                            helpers={messages.helpersPhone}
                            successes={messages.successesPhone}
                            onAdd={addPhone}
                            onChange={validatePhoneFormat}
                            value={currentPhone}
                            onDelete={(i) => dltPhone(i)}
                            add={t('Add')}
                        />
                    </Builder_>
                </Form>
                <Actions_ end>
                    <Main_ onClick={() => saveSettings()} text={t('Save')} />
                </Actions_>
            </PageAlwaysVisible>
        </Account>
    );
}
