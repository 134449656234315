import React, { useContext, useEffect, useRef, useState } from 'react';
import { check_user_exists, getCountryByIp, get_user_by_username, validateUserByPhoneUsernameExist } from '../../services/api/Users';
import { AuthContext } from '../../services/authentication/Guard';
import { useLocation, useParams } from 'react-router-dom';
import {
    Alert_,
    Builder_,
    CodeContext,
    Form,
    Input_Phone_Register,
    PageAlwaysVisible,
    PageDesktopOnly,
    PageForty,
    PageIllu,
    PageTitle,
    Steps_Actions,
    PhoneContext,
    ThemeContext,
    RegionContext,
    Input_,
    AlertContext,
    Main_Plain,
    Grey_Link,
    Actions_,
    Main_,
    Grey_,
} from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import {
    validateEmail,
    validateLoginEmail,
    validatePhone,
} from '../../helpers/validators';
import { useNavigate } from 'react-router-dom';
import { AuthIllu, GdprSection } from './AUTHENTICATE.css';
import i18n from '../../localization/i18next';
import phoneUsers from '../../data/phoneUsers.json';
import {Helmet} from 'react-helmet';

export default function AUTHENTICATE() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const lang = i18n.language ? i18n.language : 'en';
    const auth = useContext(AuthContext);
    const { theme } = useContext(ThemeContext);
    const { region, setRegion } = useContext(RegionContext);
    const [phoneCode, setPhoneCode] = useState();
    const urlParams = useParams();
    const [currentEmail, setCurrentEmail] = useState('');
    const [checkedRegion, setCheckedRegion] = useState(false);

    const clients = require('../../config/index').config;
    const PHONE_REGEX = new RegExp(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)
    //const phoneUsers = JSON.parse(phoneUsers)
    
    // const [phoneInput ,setPhoneInput] = useState('');
    const emailInput = useRef(urlParams?.email || '');
    const { alerts, setAlerts } = useContext(AlertContext);
    const [messages, setMessages] = useState({
        errors: [],
        helpers: [],
        successes: [],
    });



    //phone validator
    const handleChange = (e) => {
            let dataEmail = validateLoginEmail(e.target.value, t);

            setMessages({
                ...messages,
                helpers: dataEmail.helperemail,
                successes: dataEmail.successemail,
            });
       
        // setemailInput(e.target.value)
        emailInput.current = e.target.value;
    };

    // const handleChange = (e) => {
    //     const phoneNumber = '30' + e.target.value;
    //     // console.log('phone', phoneNumber);
    //     // const matchingUser = phoneUsers.find(
    //     //     (user) => user.username === phoneNumber
    //     // );

    //     validateUserByPhoneUsernameExist(phoneNumber)
    //     .then((res) => {
    //         const matchingUser = res?.exists;
    //         // console.log('MATCHING:', matchingUser);

    //         if (!matchingUser) {
    //             let dataEmail = validateLoginEmail(e.target.value, t);
    
    //             setMessages({
    //                 ...messages,
    //                 helpers: dataEmail.helperemail,
    //                 successes: dataEmail.successemail,
    //             });
    //         } else {
    //             setMessages({
    //                 ...messages,
    //                 helpers: '',
    //                 successes: ['User found'],
    //             });
    //         }            
    //     }).catch(err=>{});
    //     // setemailInput(e.target.value)
    //     emailInput.current = e.target.value;
    // };
    
    

    const goToLoginOrRegister = (
        alreadyUser,
        scope,
        phoneNumber,
        verifyPhone,
        username
    ) => {
        let action;
        if (alreadyUser && verifyPhone) {
            auth.verifyPhone(username);
        } else if (alreadyUser && !verifyPhone) {
            action = 'login';
            phoneNumber =
                phoneNumber === '6900000000'
                    ? (phoneNumber = 'reviewer@dummy.com')
                    : phoneNumber;
            if (urlParams.client)
                auth.login_with_custom_parameters(
                    phoneNumber,
                    action,
                    scope,
                    theme
                );
            else
                auth.login_with_custom_parameters(
                    phoneNumber,
                    action,
                    scope,
                    theme
                );
        } else if(!PHONE_REGEX.test(phoneNumber) && !alreadyUser) {
            action = 'register';
            if (urlParams.client)
                auth.login_with_custom_parameters(
                    phoneNumber,
                    action,
                    scope,
                    theme
                );
            else
                auth.login_with_custom_parameters(
                    phoneNumber,
                    action,
                    scope,
                    theme
                );
        }
        else {
            setMessages({
                ...messages,
                helpers: ['Please enter your email to procceed'],
                successes: '',
            });
        }
    };

    const checkIfUserExists = (phoneNumber) => {
        // let prep_phone_number = completePhoneNumber.replace('+', '');
        let completePhoneNumber =
            phoneNumber === '6900000000'
                ? (phoneNumber = 'reviewer@dummy.com')
                : phoneNumber;

        check_user_exists(completePhoneNumber)
            .then((res) => {
                // console.log('res', res);
                goToLoginOrRegister(
                    res?.data?.exists,
                    res?.data?.scope,
                    completePhoneNumber,
                    res?.data?.verifyPhone,
                    res?.data?.username
                );
            })
            .catch((err) => {});
    };

    //check phone on submit
    const checkEmail = (e) => {
        let erroremptyinput = (t('This field should not be empty'));
        let emailErrors = [];

        e.preventDefault();
        if (messages.helpers?.length !== 0) emailErrors = messages.helpers;

        if (emailInput.current?.length == 0) emailErrors = [erroremptyinput];

        setMessages({ ...messages, errors: emailErrors });

        //clean inputs if there is an error
        if (emailErrors?.length != 0) {
            // setPhoneInput("");
            emailInput.current = '';
        }

        if (emailErrors?.length) {
            setAlerts([
                ...alerts,
                {
                    message: {
                        text: t(
                            'Invalid inputs, please check your inputs and try again...'
                        ),
                        status: 'Error',
                    },
                },
            ]);

            // setTimeout(() => {
            //     setAlerts([]);
            // }, 8000);
        } else checkIfUserExists(emailInput.current);
    };

    // useEffect(() => {
    //   const handleKeyPress = (event) => {
    //     if (event.key === 'Enter') {
    //       checkPhone(event )
    //     }
    //   };

    //   document.addEventListener('keydown', handleKeyPress);

    //   // Cleanup the event listener when the component unmounts
    //   return () => {
    //     document.removeEventListener('keydown', handleKeyPress);
    //   };
    // }, []);
    useEffect(()=>{
        if(emailInput.current.length===10){
            get_user_by_username(emailInput.current)
            .then(res =>{
                console.log('res', res)
                if(res?.data && PHONE_REGEX.test(emailInput.current)){
                    setMessages({
                        ...messages,
                        helpers: '',
                        successes: ['User found'],
                    });
                }
            })
            .catch(err => console.log(err))
        }

    }, [emailInput.current])

    useEffect(() => {
        const get_country_code = () => {
            getCountryByIp()
                .then((res) => {
                    if (res?.country) setRegion(res?.country);
                    setCheckedRegion(true);
                })
                .catch((err) => {
                    setCheckedRegion(true);
                });
        };

        get_country_code();
    }, []);

    return (
        <>
        <Helmet>
            <title>Doitforme | Connect</title>
        </Helmet>
            {checkedRegion && (
                <PhoneContext.Provider value={{ phoneCode, setPhoneCode }}>
                    <PageForty>
                        <PageAlwaysVisible>
                            <Alert_ messages={alerts} />
                            <PageTitle>
                                <h1>{t('Connect now')}</h1>
                            </PageTitle>
                            <Form>
                                <Builder_
                                    noPads
                                    title={t('Enter your email')}
                                    last
                                >
                                    <Input_
                                        placeholder={t('Email')}
                                        required
                                        onChange={handleChange}
                                        errors={messages?.errors}
                                        helpers={messages?.helpers}
                                        successes={messages?.successes}
                                        value={emailInput?.current}
                                        defaultValue={emailInput?.current}
                                        //tips = {['If you are registered with a phone number, please enter it here']}
                                    />
                                </Builder_>
                                <Actions_  end={false}>
                              
                                    <Grey_
                                      onClick={() => navigate(-2)}
                                      text={t('Back')}
                                      iconLeft = "Back"
                                    />
                                    <Main_
                                        onClick={checkEmail}
                                        text={t('Next')}
                                        iconRight = "Next"
                                        /> 
                                </Actions_>
                            </Form>
                            <GdprSection>
                                <p>
                                    {t('By accessing and using our app, you acknowledge that you have read and agree to our')}
                                    <a
                                        href={`${clients['fahed']}/${lang}/data-protection-policy`}
                                    >
                                        {t('Privacy Policy GDPR')}
                                    </a>
                                    {t('and')}
                                    <a
                                        href={`${clients['fahed']}/${lang}/terms-and-conditions`}
                                    >
                                        {t('Terms and Conditions')}
                                    </a>
                                    {t('of')} DO IT FOR ME PC.
                                </p> 
                            </GdprSection>
                            {/* <a text = 'Terms and Conditions' onClick = {()=>window.location.assign(`${clients['fahed']}/${lang}/terms-and-conditions`)}/> */}
                        </PageAlwaysVisible>
                        <PageDesktopOnly>
                            <AuthIllu>
                                <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/erised/Password.svg" />
                            </AuthIllu>
                        </PageDesktopOnly>
                    </PageForty>
                </PhoneContext.Provider>
            )}
        </>
    );
}
