import i18n from 'i18next'
import LanguageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import { getTranslations } from '../services/api/Translations';
import convert_translations from '../helpers/convertTranslations';
import store from '../redux/store';
import { update_options_cookie } from '../helpers/initMenuOptions';

const fetch_translations_from_database = () => {
    return new Promise((resolve, reject) => {
        let body = {client: process.env.REACT_APP_NAME}
        getTranslations(body).then(data => {
            if (!data) resolve ({
                data: {
                    translations: [],
                    hasFailed: true,
                }
            })
            else resolve(data)
        })
    })
}

let languageEL;
let languageEN;
let languageDE;
let languageIT;


let cache_fetched_translations = store.getState().translations;
let nowTime = new Date(Date.now()).getTime();
let cacheStoreTime = cache_fetched_translations?.info?.expiry;
let expiredCache = (nowTime > cacheStoreTime) ? true : false;

languageEL = cache_fetched_translations?.languages?.el
languageEN = cache_fetched_translations?.languages?.en
languageDE = cache_fetched_translations?.languages?.de
languageIT = cache_fetched_translations?.languages?.it

const getLanguageFromLocalStorage = () => {
    return store.getState().selectedLanguage;
};
  
const lng = getLanguageFromLocalStorage();

const initaliaze_i18n = (cache) => {

    if (process.env.REACT_APP_SERVER == 'STAGING') {
        console.log('This is right before initializing i18n. ')
        console.log('"cache" paramater is equal to ', cache);
        console.log(cache?.languages?.el);
        console.log(cache?.languages?.en);
        console.log(cache?.languages?.de);
        console.log('or', )
        console.log(languageEL)
        console.log(languageEN)
        console.log(languageDE)
        console.log(languageIT)
    }

    i18n
        .use(XHR)
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
            detection: {
                        order: ['path','navigator', 'cookie', 'sessionStorage', 'htmlTag', 'localStorage', 'subdomain']},
                        lookupFromPathIndex: 1,
                        lookupFromSubdomainIndex: 1,
            resources: {
                el: {translations: cache ? cache?.languages?.el : languageEL},
                en: {translations: cache ? cache?.languages?.en : languageEN},
                de: {translations: cache ? cache?.languages?.de : languageDE},
                it: {translations: cache ? cache?.languages?.it : languageIT}
            },
            lng: lng,
            fallbackLng: "en",
            debug: false,
            ns: ["translations"],
            defaultNS: "translations",
            keySeparator: ".",
            interpolation: {
                escapeValue: false,
                formatSeparator: ","
            },
            supportedLngs:['el', 'en', 'de', 'it'],
            react: {
                useSuspense: false,
                wait: true,
                bindI18n: 'languageChanged loaded',
                bindStore: 'added removed',
                nsMode: 'default'
            }
        })  
        .then((t) => {
            // console.log('i18init then', t);
            store.dispatch({type:'LANGUAGE_UPDATE', payload: lng})
            // console.log('i18init then (after dispatch)');
            const userId = store.getState()?.user?.user?.sub;
            // console.log('i18init then (after getting user)');
            i18n.on('languageChanged', (lng) => {
                // console.log('i18n ON', lng);
                store.dispatch({type:'LANGUAGE_UPDATE', payload: lng})
                if ( lng != store.getState().user?.user?.preferences?.language ) update_options_cookie(userId)
            })
            // console.log('i18init then (end)');
        }).catch(err=>{});
}

if(!cache_fetched_translations || expiredCache){
    
    fetch_translations_from_database().then(data => {

        if (process.env.REACT_APP_SERVER == 'STAGING') {
            console.log('This data is going into the convert_translations: ')
            console.log(data?.data?.translations);
        }
        
        var translations = convert_translations(data?.data?.translations);      //converts fetched db translations to lang based objects

        languageEL = translations.languageEL.translations;
        languageEN = translations.languageEN.translations;
        languageDE = translations.languageDE.translations;
        languageIT = translations.languageIT.translations;
    
        let caching_translations = {
            info: {
                expiry: data?.data?.hasFailed ? new Date(Date.now()).getTime() + 10 : new Date(Date.now()).getTime() + 300000
            },
            languages: {
                el: translations.languageEL.translations,
                de: translations.languageDE.translations,
                en: translations.languageEN.translations,
                it: translations.languageIT.translations
            }
        }
        if (process.env.REACT_APP_SERVER == 'STAGING') {
            console.log('And this data are going to get stored in the cache: ')
            console.log(caching_translations);
        }
        store.dispatch({type:'SAVE_TRANSLATIONS', payload: caching_translations});

        initaliaze_i18n();
    })
}
else {
    initaliaze_i18n();
}


export default i18n;