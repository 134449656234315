import { CardsSixty, CardsTools, Grey_Link, On_Click_Card, PageAlwaysVisible, PageFull, PageTitle, PageTools, PageToolsSixty } from 'monica-alexandria'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import Aside_ from '../../components/navigation/Aside_'
import store from '../../redux/store';
import i18n from '../../localization/i18next';
import { Helmet } from 'react-helmet';

export default function SETTINGS() {
    const navigate = useNavigate();
    const handleClick = (toPath) =>{
      navigate(`/${lang}/settings/${toPath}`);
    }
    const {t} = useTranslation();
    const lang = store.getState().selectedLanguage
  return (
    <PageFull>
        <Helmet>
            <title>Doitforme | User settings</title>
        </Helmet>
        <PageTitle>
            <Grey_Link
                iconLeft="Back"
                noPadding
                onClick={() => navigate(`/${lang}`)}
            />
            <h1>{t('Settings')}</h1>
        </PageTitle>
        <CardsTools>
            <On_Click_Card 
                icon = 'User'
                subtitle = {t('My account')}
               onClick = {()=>handleClick('my-account')}
            />
            <On_Click_Card 
                icon = 'Heart'
                subtitle = {t('Preferences')}
                onClick = {()=>handleClick('preferences')}
            />
            <On_Click_Card 
                icon = 'Lock'
                subtitle = {t('Privacy and security')}
                onClick = {()=>handleClick('privacy-and-security')}
            />
        </CardsTools>
    </PageFull>
  )
}
