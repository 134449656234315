import {
    CardsTools,
    On_Click_Card,
    PageFull,
    PageTitle,
    PageTools,
} from 'monica-alexandria';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Panel = styled(PageFull)``

export default function PANEL() {
    const navigate = useNavigate();
    const handlenav = (toPath) => {
        navigate(toPath);
    };
    const { t } = useTranslation();
    return (
        <Panel>
            <PageTitle>
                <h1>{t("Platform's admin panel")}</h1>
            </PageTitle>
            <CardsTools>
                <On_Click_Card
                    onClick={() => handlenav('users')}
                    icon="User"
                    title={t('Users')}
                />
                <On_Click_Card
                    onClick={() => handlenav('requests')}
                    icon="Tip"
                    title={t('Requests')}
                />
                <On_Click_Card
                    onClick={() => handlenav('translations')}
                    icon="Flag"
                    title={t('Translations')}
                />
                <On_Click_Card 
                    onClick={() => window.open("https://insights.hotjar.com/login?next=%2Fsites%2F3900391%2Fheatmap")}
                    imgBehave="Cover"
                    img = "https://genius1071.friktoriaservers.net/doitforme/morning/app/global/Hotjar.png"
                    title = "i.reda"
                />
            </CardsTools>
        </Panel>
    );
}
