import { Single_ } from 'monica-alexandria';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import store from '../../redux/store';
import { AuthContext } from '../../services/authentication/Guard';
import { Helmet } from 'react-helmet';

export default function RESET_PASSWORD() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lang = i18n.language ? i18n.language : 'en';
    const auth = useContext(AuthContext);
    const hint = store.getState().user?.user?.username;

    return (
        <>
            <Helmet>
                <title>Doitforme | Reset password confirmation</title>
            </Helmet>
            <Single_
                backgroundImage={
                    'https://genius1071.friktoriaservers.net/doitforme/morning/app/erised/keyParachute.svg'
                }
                title={t('Do you want to reset your password ?')}
                onClickMain={() => navigate(`/${lang}`)}
                textMain={t('Back to my account')}
                onClickGrey={() => auth.reset_password(hint)}
                textGrey={t('Reset my password')}
            />
        </>
    );
}
