import {
    AlertContext,
    Alert_,
    Builder_,
    CardsSixty,
    CardsTools,
    Confirmation_,
    Cta_,
    Dropdown_,
    Form,
    Full_Card_Loader,
    Google_Search,
    Main_,
    On_Click_Card,
    PageFull,
    PageTitle,
    PageTools,
    Red_,
    Slider_,
    Filters_,
    TableDesktop,
    TH,
    Table,
    TRH,
    TR,
    TD,
    Main_Plain,
    Main_Loader,
    Grey_Link,
    TableMobile,
    TableCard,
    TableCardActions,
    TBody,
    THead,
    IconSvg,
    Sub_Color_Coding
} from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { get_users, update_user } from '../../../services/api/Users';
import { useLocation, useNavigate } from 'react-router-dom';
import { User_Filters } from './components/User_Filters';
import { User_Info } from './components/User_Info';

export default function USERS() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();
    const { alerts, setAlerts } = useContext(AlertContext);
    //search
    const [inputActive, setInputActive] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [size, setSize] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [slider, toggleSlider] = useState(false);
   
    const [users, setUsers] = useState({data:[],loading:true});
    const [displayedUsers, setDisplayedUsers] = useState([]);

    // filters
    const [filters, toggleFilters] = useState(false);
    const [sortHandle , setSortHandle] = useState({key:'', order:'',value:''})
 
     const [selectedOptions, setSelectedOptions] = useState([])
     const [allTimeSubs, setAllTimeSubs] = useState(false)
    

     const [renderedDates, setRenderedDates] = useState([]);

    const [dates, setDates] = useState({
        data:'',
        sinceDate: '',
        sinceDate_key: 'sinceDate',
        untilDate: '',
        untilDate_key: 'untilDate',
    });


    const [activeUser, setActiveUser] = useState(false);

    const openUserSlider = (user) => {
        //toggleSlider(!slider);
        setActiveUser(user);
        if (user?.userId!=='false') window.open(`http://localhost:5600/el/akihiko/erised/users/${user?.userId}`)
    };

    console.log('ACTIVE USER', activeUser);

    // reload only the updated user
    const modifyUser = (user) => {

        const tempUsers = JSON.parse(JSON.stringify(displayedUsers))
        let index = tempUsers.findIndex(item => item.userId == user.userId)
        if (index !== -1) { 
            tempUsers[index] = user;
            setDisplayedUsers(tempUsers);
        }
    }

    const setAlertMessage = (message, status) => {
        setAlerts([...alerts, { message: { text: message, status: status } }]);
    };

    const updateUser = () => {
        let data = users;
        update_user(data) .then(res => {
           // setUsers(data); 
          })
          .catch(error => {
          });
    }

    useEffect(() => {
        let options = {filters: {}, 
        sorting: {}
        }

        if ( sortHandle.key ) {
            options.sorting[sortHandle.key] = sortHandle.order
        }
      
        selectedOptions.forEach(option => {
            if (option.key_field) {
                if (!options.filters.subscriptions) {
                    options.filters.subscriptions = {};
                }
                if (!options.filters.subscriptions[option.key_field]) {
                    options.filters.subscriptions[option.key_field] = [];
                }
                options.filters.subscriptions[option.key_field].push(option.key);
            }
        });

        // if ( allTimeSubs?.length ) {
        //     options.filters.subscribers = {}
        //     // options.filters.subscribers[allTimeSubs.key_field] = allTimeSubs.key
        //     options.filters.subscribers[allTimeSubs.key_field] = allTimeSubs.key;
        //   //  options.filters.subscribers ;
        // }

        if (allTimeSubs) {
            options.filters.subscribers = {
                key_field: 'allTime',
                key: true
            };
        }
        if ( selectedOptions.sinceDate && dates.untilDate) {
            options.filters.dates = {}
            options.filters.dates.sinceDate = dates.sinceDate
            options.filters.dates.untilDate = dates.untilDate
        }

        if ( dates.sinceDate && dates.untilDate) {
            options.filters.dates = {}
            options.filters.dates.sinceDate = dates.sinceDate
            options.filters.dates.untilDate = dates.untilDate
        }

        if (Object.keys(options.filters).length === 0 && options.filters.constructor === Object) {
            delete options.filters;
        } 

        if (Object.keys(options.sorting).length === 0 && options.sorting.constructor === Object) {
            delete options.sorting;
        } 
        // console.log("options",options)

        get_users(options)
            .then(res => { setUsers({
                ...users,
                data: res.data?.data,
                loading: false,
              })
             })
            .catch((err) => {
                setUsers({
                    ...users,
                    loading: false,
                  }) 
            });
    }, [sortHandle, selectedOptions, allTimeSubs, dates]);


    const applySearchResults = () => {
        if (searchResults?.data?.length) setDisplayedUsers(searchResults?.data);
        else setDisplayedUsers(users?.data);
    };

    useEffect(() => {
        if (searchResults?.data?.length === 0)
            setDisplayedUsers(users.data);
    }, [users.data]);

    useEffect(() => {
        applySearchResults();
    }, [searchResults?.input]);

    useEffect(() => {
        if ( location?.state?.alert ) setAlertMessage(location.state.message, location.state.alert)
    },[])

    console.log('USERS',users)
    console.log('selectedOprions',selectedOptions)
    return (
        <PageFull>
            <Alert_
                messages={alerts}
            />
            {slider ?
                <User_Info
                    activeUser={activeUser} 
                    toggleSlider={toggleSlider} 
                    notify={(user) => modifyUser(user)}
                    setAlertMessage={setAlertMessage}
                />
            :
                null
            }
            {filters ? 
                <User_Filters 
                    users={users} 
                    toggleFilters={toggleFilters}
                    {...{sortHandle, setSortHandle}}
                    {...{renderedDates, setRenderedDates}}
                    {...{ dates, setDates }} 
                    {...{ selectedOptions, setSelectedOptions }} 
                    {...{ allTimeSubs, setAllTimeSubs }} 
                /> 
            :
                null
            }
            <PageTitle>
                <Grey_Link iconLeft="Back" noPadding onClick = {()=> navigate(-1)} />
                <h1>{t('Users')}</h1>
            </PageTitle>
            <Filters_ 
                filters={t("Filters")}
                onFilters = {() => toggleFilters(!filters)}
                search={
                    <Google_Search
                        placeholder={t('Search users')}
                        file={users.data}
                        fileKeys={['username', 'firstname', 'lastname']}
                        sanitize={false}
                        setResults={(data) => setSearchResults(data)}
                        debounceTime={750}
                        setInputSize={(size) => setSize(size)}
                        isCaseSensitive={false}
                        maxAcceptedScore={0.6}
                        minMatchCharacters={0}
                    />
                }
            />
            {!users.loading ? 
                <TableDesktop>
                    <Table>
                        <THead>
                            <TRH>
                                <TH></TH>
                                <TH>{t("Subscriptions")}</TH>
                                <TH>{t("Username")}</TH>
                                <TH>{t("First name")}</TH>
                                <TH>{t("Last name")}</TH>
                                <TH>{t("Created")}</TH>
                            </TRH>
                        </THead>
                        <TBody>
                            {displayedUsers.map((user, i) => 
                                <TR key={i}>
                                    <TD>
                                        <Main_Plain iconLeft="EyeOpen" noPadding onClick={()=>openUserSlider(user)} />
                                    </TD>
                                    <TD>
                                    {user.subscriptions?.map((sub) =>
                                      <Sub_Color_Coding 
                                      bond={sub?.category === "bond"}
                                      lois={sub?.category === "lois"}
                                      dailyprofit={sub?.category === "dailyprofit"}
                                  />
                                )}
                                      
                                    </TD>
                                    <TD>{user?.userId!=='false' ? user.username :  user?.email}</TD>
                                    <TD>{user?.userId!=='false' ? `${user.firstname}` : `${user?.name}`}</TD>
                                    <TD>{user?.userId!=='false' ? `${user.lastname}` : `${user?.name}`}</TD>
                                    <TD>{new Date(user.createdAt).toLocaleDateString()}</TD>
                                </TR>
                            )}
                        </TBody>
                    </Table>
                </TableDesktop>
            :
                <Main_Loader />
            }
            {!users.loading ?
                <TableMobile>
                    <CardsSixty>
                        {displayedUsers.map((user, i) => 
                            <TableCard key={i}>
                                <TableCardActions>
                                    {true ? 
                                        <Sub_Color_Coding 
                                            bond={true}
                                            lois={true}
                                            dailyprofit={true}
                                        />
                                    :
                                        <div></div>
                                    }
                                    <Main_Plain noPadding iconLeft="EyeOpen" onClick={()=>openUserSlider(user)} />
                                </TableCardActions>
                                <table>
                                    <TBody>
                                        <TR>
                                            <TD>
                                                <IconSvg Icon="Email" />
                                            </TD>
                                            <TD>
                                                {user?.userId!=='false' ? user.username :  user?.email}
                                            </TD>
                                        </TR>
                                        <TR>
                                            <TD>
                                                <IconSvg Icon="User" />
                                            </TD>
                                            <TD>
                                                {user?.userId!=='false' ? `${user.firstname}` : `${user?.name}`} {user?.userId!=='false' ? `${user.lastname}` : `${user?.name}`}
                                            </TD>
                                        </TR>
                                        <TR>
                                            <TD>
                                                <IconSvg Icon="Calendar" />
                                            </TD>
                                            <TD>
                                                {new Date(user.createdAt).toLocaleDateString()}
                                            </TD>
                                        </TR>
                                    </TBody>
                                </table>
                            </TableCard>
                        )}
                    </CardsSixty>
                </TableMobile>
            :
                <Main_Loader />
            }
        </PageFull>
    );
}
