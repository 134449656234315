import React, { useState, useEffect } from 'react';

const Countdown_ = ({ seconds, currentCountdown }) => {
  const [countdown, setCountdown] = useState(seconds);

  useEffect(() => {
    // Exit early when countdown reaches 0
    if (countdown <= 0) return;

    // Update countdown every second
    const intervalId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Clean up interval when component unmounts or countdown reaches 0
    return () => clearInterval(intervalId);
  }, [countdown]);

  // Format seconds into minutes and seconds
  const displayCountdown = () => {
    const minutes = Math.floor(countdown / 60);
    const remainingSeconds = countdown % 60;
    currentCountdown(`${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`)
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
        <p>{displayCountdown()}</p>
  );
};

export default Countdown_;
