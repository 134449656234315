// This page works as an authentication toolbox and gateway between the App and the Auth Service.
// Any page that may need authentication tools or user credentials, 
// must set this page as a parent (in Router.js)

import { React, useEffect, useState, createContext, useContext } from 'react';
import { useKeycloak } from '@react-keycloak/web'
import { expireSession, get_user_settings_from_database_and_save_user_to_cache } from './SessionService';
import { checkPermissions } from '../../helpers/checkPermissions';
import i18n from '../../localization/i18next';
import { config } from '../../config';
import q from 'query-string'
import store from '../../redux/store';

const protocol = process.env.REACT_APP_ERISED_FRONTEND_PROTOCOL;
const url = process.env.REACT_APP_ERISED_FRONTEND_HOST;
const port = process.env.REACT_APP_ERISED_FRONTEND_PORT;

const clients = require('../../config/index').config
const client = process.env.REACT_APP_CLIENT;

const authClient = process.env.REACT_APP_AUTH_CLIENT;

export const AuthContext = createContext();

////////////////////////////////////////////////////////////////////
// Performs authentication before proceeding, loads user credentials
////////////////////////////////////////////////////////////////////
export const GuardedRoute = ({children, ...props}) =>  {

    const { keycloak } = useKeycloak();
    const [ loadedUser, showSpinner] = useState({auth: false, session: false})
    const params = q.parse(window.location.search);

    const lang = i18n.language ? i18n.language : 'el';
    
    const generate_redirect_link = () => {

        let redirectUrl;
        if (params?.redirectUri) redirectUrl = window.location.search.replace('?redirectUri=', '');
        else redirectUrl = protocol + url + port;

        return redirectUrl
    }

    const login = (authclient, redirectUri) => {
        let redirectUrl = '';
        // expireSession();
        if (redirectUri) redirectUrl = `?redirectUri=${redirectUri}`
        window.location.assign(`${config.ERISED_FRONTEND_URL}/${lang}/auth/${redirectUrl}`)
    }

    const verifyPhone = (username, redirectUri) => {
        let redirectUrl = '';
        // expireSession();
        if (redirectUri) redirectUrl = `?redirectUri=${redirectUri}`
        window.location.assign(`${config.ERISED_FRONTEND_URL}/${lang}/change-username?phone=${username}`)
    }


    const login_with_custom_parameters = (hint, action, scope, theme) => {
        // let redirectParam = `${protocol}${url}${port}/${lang}/verify-account?redirectUrl=${generate_redirect_link()}`
        // console.log(theme)
        let redirectParam = generate_redirect_link()

        expireSession();
        let loginUrl = keycloak.createLoginUrl({action:action, redirectUri : redirectParam,
                                                locale: lang, loginHint:hint})
        loginUrl = loginUrl.replace(/([?&])kc_action=[^&]+/, '');
        window.location.assign(loginUrl+`&theme=${theme}`)
    }

    const logout = () => {
        expireSession();
        let logoutUrl = keycloak.createLogoutUrl({redirectUri : generate_redirect_link(),
                                                locale: lang})
        window.location.assign(logoutUrl)
    }

    const reset_password = (hint) =>{
        let resetUrl = keycloak.createLoginUrl({action:"UPDATE_PASSWORD", scope:'phone',locale: lang, loginHint:hint, redirectUri:protocol+url+port+'/'+lang+'/success-reset'})
        keycloak.logout({redirectUri: resetUrl});
    }

    const register = () => {
        expireSession();
        let registerUrl = keycloak.createLoginUrl({action:'register', redirectUri : generate_redirect_link(),
                                                locale: 'el'})
        window.location.assign(registerUrl)
    }

    const check_permissions = (roles) => {
        if (!checkPermissions(props.role, roles)) {
            window.location.assign(`${clients[client]}/${lang}/access-denied`);
        }
    }

    const generate_password_link = (theme) =>{
        let passwordUrl = keycloak.createAccountUrl({redirectUri: `${config.ERISED_FRONTEND_URL}/${lang}/settings/privacy-and-security/`})
        const url = new URL(passwordUrl);
        const params = new URLSearchParams(url.search);
        params.set('theme', theme); // Replace 'your_theme_value' with the desired theme value
      
        url.search = params.toString();
        const updatedUrl = url.toString();
    
        window.location.assign(updatedUrl.replace('/account', '/account/password'));
     }

    useEffect(() => {
        if(!keycloak.authenticated && props.shouldRedirect) {
            login(authClient, window?.location?.href)
        }
        keycloak.loadUserInfo().then((user) => {
            if(!user.email_verified && !props.verified) window.location.assign(`${protocol}${url}${port}/${lang}/verify-account?redirectUrl=${generate_redirect_link()}`)
            check_permissions(user?.roles);
            get_user_settings_from_database_and_save_user_to_cache(keycloak, user)
                .then(data => showSpinner({auth: keycloak.authenticated, session: data}))
        }).catch(() => {
            expireSession();
            showSpinner({...loadedUser, session: true});
        })
    }, [])

    useEffect(() => {
        keycloak.onTokenExpired = () => {
            keycloak.updateToken()
                .then(() => store.dispatch({type:'KEYCLOAK', payload: {user: store.getState().user?.user, token: keycloak.token}}))
                .catch(err=> {})
        };
    },[keycloak])
    if (!loadedUser.session) return <></>
    if (props.shouldRedirect && !keycloak.authenticated) return <></>
    else return (<AuthContext.Provider role={props.role} value={
                                        { login: () => login(),
                                        verifyPhone:(username, redirectUri) => verifyPhone(username, redirectUri),
                                        register: () => register(),
                                        logout: () => logout(),
                                        reset_password: (hint)=> reset_password(hint),
                                        generate_password_link: (theme) => generate_password_link(theme),
                                        login_with_custom_parameters: (client, hint, action, params, theme) => login_with_custom_parameters(client, hint, action, params, theme),
                                        authenticated: keycloak.authenticated,
                                        user: loadedUser.session}
                                        }>{children}</AuthContext.Provider>)
}


