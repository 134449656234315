import {
    Builder_,
    Checkbox_,
    Dates_,
    Dropdown_,
    Main_,
    Radio_,
    Slider_,
    countries,
} from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const UserFilters = styled.div``;

export const User_Filters = (props) => {

    const { t } = useTranslation();

    const sortOptions = {
        UseridDesc: { key: 'userId', order: -1 ,text: 'Userid (Desc)' ,value: 'UseridDesc'},
        UseridAsc: { key: 'userId', order: 1, text: 'Userid (Asc)' , value: 'UseridAsc'},
        NameAsc: { key: 'firstname', order: 1 ,text: 'Name (Asc)', value: 'NameAsc'},
        NameDesc: { key: 'firstname', order: -1 ,text: 'Name (Desc)', value: 'NameDesc' },
    };

      const [filterOptions, setFilterOptions] = useState( { 
        NonSubscribers: { checked: false, text: 'Non subscribers'  },
        ActiveSubscribers: { checked: false, text: 'Active Subscribers'},
        AllTimeSubscribers: { checked: false, text: 'All Time Subscribers'},
        UnRegistered: {checked: false , text: 'Unregistered Subscribers'},
        AdSnap : {checked: false ,text: 'AdSnap', filterkey: 'lois' },
        Questad: {checked: false, text: 'Questad',  filterkey: 'dailyprofit' },
        MiniWebsite: {checked: false , text: 'Mini Website'}
    })

    // const [renderedDates, setRenderedDates] = useState([]);

    const maxDate = new Date();


    const formatDate = (date) => {
        let to_MM_DD_YYYY_array = date.toLocaleDateString().split('/');
        let to_YYYY_MM_DD_array = [
            to_MM_DD_YYYY_array[2]?.length === 1 ? 0+to_MM_DD_YYYY_array[2] : to_MM_DD_YYYY_array[2],
            to_MM_DD_YYYY_array[0]?.length === 1 ? 0+to_MM_DD_YYYY_array[0] : to_MM_DD_YYYY_array[0],
            to_MM_DD_YYYY_array[1]?.length === 1 ? 0+to_MM_DD_YYYY_array[1] : to_MM_DD_YYYY_array[1],
        ];
        return to_YYYY_MM_DD_array.join('-');
    };

    const onChangeDates = (selections) => {
        props.setRenderedDates(selections);
        if (selections.length === 2)
           props.setDates({
                ...props.dates,
                sinceDate: formatDate(selections[0]),
                untilDate: formatDate(selections[1]),
            });
    };
    // const handleCheckboxChange = (event) => {
    //     const checkboxValue = event.target.id;
    //     const isChecked = event.target.checked;
    //     console.log('TARGET', event.target.id)
    //     console.log('ISCHECKED' , isChecked)
    //     setFilterOptions(() => ({
    //       ...filterOptions,
    //       [checkboxValue]: { checked: isChecked },
    //     }));
    // }

    const handleCheckboxChange = (id) => {
        setFilterOptions(prevOptions => {
          const updatedOptions = { ...prevOptions };
          updatedOptions[id].checked = !updatedOptions[id].checked;
            
            // Add or remove the option from the selectedOptions list 
            //saved for later use when you can choose multiple options
            // if (updatedOptions[id].checked) {
            //     props.setSelectedOptions((prevSelectedOptions) => [
            //     ...prevSelectedOptions,
            //     updatedOptions[id].filterkey
            //     ]);
            // } else {
            //     props.setSelectedOptions((prevSelectedOptions) =>
            //     prevSelectedOptions.filter((optionKey) => optionKey !== updatedOptions[id].filterkey)
            //     );
            // }
  
          return updatedOptions;
        });
      };

    const handleRadioChange = (id, key_field, key) => {
        props.setAllTimeSubs({...props.allTimeSubs, data:'subscribers', key_field: key_field, key: key, name: id})
    };

    const handleShowCheckboxChange = (id, key_field, key) => {
        const index = props.selectedOptions.findIndex(option => option.name === id);
        if (index === -1) {
            props.setSelectedOptions([...props.selectedOptions, { data: 'subscriptions', key_field, key, name: id }]);
        } else {
            const updatedOptions = [...props.selectedOptions];
            updatedOptions.splice(index, 1);
            props.setSelectedOptions(updatedOptions);
        }
    };


    const handleSortChange = (sortOption) =>{
        if (sortOptions.hasOwnProperty(sortOption)) {
            const selectedSortOption = sortOptions[sortOption];
            props.setSortHandle({ ...props.sortHandle, ...selectedSortOption });
        }
    }
 
    return (
        <UserFilters>   
            <Slider_ 
                title={t("Filters")} 
                text={t("Apply")}
                onClose = {() => props.toggleFilters(false)}
                onClick = {() => props.toggleFilters(false)}
            >
            <Builder_ title={t("Sort by")}>
            <Dropdown_>
                <select defaultValue={props.sortHandle.value}  onChange={(e) => handleSortChange(e.target.value)}>
                {Object.entries(sortOptions).map(([key, option]) => (
                    <option key={key} value={key}>
                    {option.text}
                    </option>
                ))}
                </select>
            </Dropdown_>
            </Builder_>
            <Builder_ title={t("Show only")}>
                <Checkbox_ 
                    text={t("Non subscribers")}
                    value="NonSubscribers"
                    id="NonSubscribers"
                    name = 'option'
                    checked={props.selectedOptions?.some(option => option.name ===  'NonSubscribers')}
                    onClick={()=>handleShowCheckboxChange('NonSubscribers', 'active', false)}
                />
                <Checkbox_
                    text={t("Active subscribers")}
                    value="ActiveSubscribers"
                    id="ActiveSubscribers"
                    name = 'option'
                    checked={props.selectedOptions?.some(option => option.name === 'ActiveSubscribers')}
                    //onClick={()=> props.setAllTimeSubs(!props)}
                    onClick={() => handleShowCheckboxChange('ActiveSubscribers' ,'active', true)}
                />
                <Checkbox_
                    text={t("All time subscribers")}
                    value="AllTimeSubscribers"
                    id="AllTimeSubscribers"
                    name = 'option'
                    checked={props.allTimeSubs}
                    onClick={()=> props.setAllTimeSubs(!props.allTimeSubs)}
                  
                />
                {/* <Checkbox_
                    text={t("Unregistered Subscribers")}
                    value="UnregisteredSubscribers"
                    id="UnregisteredSubscribers"
                    name = 'option'
                    checked={props.selectedOptions?.some(option => option.name === 'UnregisteredSubscribers')}
                    onClick={() => handleShowCheckboxChange('UnregisteredSubscribers', 'customer', true)}
                /> */}
                <Checkbox_
                    text="Adsnap"
                    id="AdSnap"
                   // name = 'option'
                    checked={props.selectedOptions?.some(option => option.name === 'AdSnap')}
                    onClick={() => handleShowCheckboxChange('AdSnap','category','lois')}
                />
                <Checkbox_
                    text="Questad"
                    id="Questad"
                   // name = 'option'
                    checked={props.selectedOptions.some(option => option.name === 'Questad')}
                    onClick={() => handleShowCheckboxChange('Questad','category', 'dailyprofit' )}
                />
                {/* <Radio_
                    text="Mini Website"
                    id="MiniWebsite"
                    name = 'option'
                    checked={props.selectedOption.name ==='MiniWebsite'}
                    onChange={()=>handleRadioChange('MiniWebsite', 'plan_category', 'monica')}
                />  */}
            </Builder_>
            <Builder_ title={t("Show users that registered on specific date range")}>
                <Dates_ 
                   onChange={onChangeDates}
                   value={props.renderedDates}
                   selectRange={true}
                   dateFullCellRender={(date) => date.date()}
                   maxDate={maxDate}
                />
            </Builder_>
            </Slider_>
        </UserFilters>
    );
};
