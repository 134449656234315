import {useDropzone} from 'react-dropzone';
import React, {useEffect, useState} from 'react';
import { IconSvg } from 'monica-alexandria';
import styled from 'styled-components';

const Upload = styled.button`
  background: var(--main);
  border-radius: var(--normalRadius);
  border: 1px solid var(--main);
  display: flex;
  align-items: center;
  gap: 1rem;
  outline: 0;
  cursor: pointer;
  transition-property: background, border;
  transition-duration: .5s;
  padding: ${p => 
      p.noPadding === true ? '0 !important' :
      p.size === 'small' ? 'var(--smallPads)' :
      p.size === 'large' ? 'var(--largePads)' :
      'var(--normalPads)'
  };


  svg{
    fill: var(--white);
      transition-property: fill;
      transition-duration: .5s;
      width: ${p => 
          p.size === 'small' ? 'var(--smallIcon)' :
          p.size === 'large' ? 'var(--largeIcon)' :
          'var(--normalIcon)'
      };
  }

  span{
    color: var(--white);
      white-space: nowrap;
      transition-property: color;
      transition-duration: .5s;
      font-size: ${p => 
          p.size === 'small' ? 'var(--smallText)' :
          p.size === 'large' ? 'var(--largeText)' :
          'var(--normalText)'
      };
  }

  &:hover{
        background: var(--mainLight);
        border: 1px solid var(--mainLight);
    }

  &:active{
      transform: scale(.97);
  }
`

export default function Upload_(props) {
    const [files, setFiles] = useState(props.files);

    const boomRejected = (type, filename) => {
      props.boomRejected(type, filename); 
    }

    const {getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
    accept: props.accept ? props.accept : 'image/svg, image/jpeg, image/png, image/jpg, video/mp4, video/mpeg, video/webm',
    onDrop: acceptedFiles => {

        let files=acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        }))
      
        let tempFiles= []
        files.forEach((file, i) => {
          if (props.type==='video' && file.size > process.env.REACT_APP_MAX_VIDEO_SIZE) {
            boomRejected('videoSize', file.name)
          }
          else if ( file.type != 'image/png' && file.type != 'image/jpeg' ) {
            boomRejected('fileMimetype', file.name)
          }
          else if ((props.type==='avatar' || props.type==='cover') && file.size > process.env.REACT_APP_MAX_IMAGE_SIZE) {
            boomRejected('fileSize', file.name)
          }
          else {
            tempFiles.push(file)
          }
        });
        if (tempFiles.length > 0) setFiles(tempFiles);
      },
    onDropRejected: () =>  {
      if (props.type==='video') boomRejected('videoType', '');
      else if (props.type==='avatar' || props.type==='cover') boomRejected('imageType', '');
    },
    maxFiles:props.maxFiles
    });

    const sendFilesToParent = () => {
        props.getFilesFromChild(files);    
    }

    useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    sendFilesToParent();
    //files.forEach(file => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    return (
      <Upload {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <IconSvg Icon='Upload'/>
        <span>{props.text}</span>
        <input {...getInputProps()} />
      </Upload>
    );
}




