import React, { useContext, useEffect, useRef, useState } from 'react'
import { Actions_, AlertContext, Alert_, Builder_, Form, Input_, Input_Phone_Register, Main_, PageAlwaysVisible, PageDesktopOnly, PageForty, PageIllu, PageSubtitle, PageTitle, Single_, Steps_Actions } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import i18n from '../../localization/i18next';
import { validateEmail, validateOTP } from '../../helpers/validators';
import store from '../../redux/store';
import q from 'query-string'
import { get_user_by_username, verifyOtpandChangeUsername } from '../../services/api/Users';
import { Helmet } from 'react-helmet';
const clients = require('../../config/index').config;

export default function CHANGE_USERNAME() {
    const lang = i18n.language ? i18n.language : 'en';
    const params = q.parse(window.location.search);

    const { t } = useTranslation();

    const { alerts, setAlerts } = useContext(AlertContext);

    const emailInput = useRef(''); 
    const otpInput = useRef(''); 
    const [user, setUser] = useState('')
    const [code, setCode] = useState();
    const [messages, setMessages] = useState({
        errorsEmail: [], 
        helpersEmail: [],
        successesEmail: [],
        errorsOTP: [], 
        helpersOTP: [],
        successesOTP: [],
    });

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    const handleEmail = (e) => {
        let dataEmail = validateEmail(e.target.value, t);
        setMessages({   
            ...messages,
            helpersEmail: dataEmail?.helperemail,
            successesEmail: dataEmail?.successemail,
        });
        emailInput.current = e.target.value;
    };

    const handleOTP = (e) => {
        let dataOTP = validateOTP(e.target.value, t);
        setCode(e.target.value)
        setMessages({   
            ...messages,
            helpersOTP: dataOTP?.helperOTP,
            successesOTP: dataOTP?.successOTP,
        });
        otpInput.current = e.target.value;
    };

    const get_user =() =>{
        // console.log('SENDING user id', params?.phone)
        get_user_by_username(params?.phone)
        .then(res =>{
            // console.log('eress', res)
            setUser(res?.data)
        })
        .catch(err=> {})
    }

    const send_Verification_Code = (e) => {

     let erroremptyinput = (t('This field should not be empty'));
     let otpErrors = [];
     let emailErrors = [];

     e.preventDefault();
     //if we have helpers they become errors
     if (messages.helpersEmail?.length !== 0) emailErrors = messages.helpersEmail;
     if (messages.helpersOTP?.length !== 0) otpErrors = messages.helpersOTP;

     //empty input
     if (emailInput.current?.length == 0) emailErrors = [erroremptyinput];
     if (otpInput.current?.length == 0) otpErrors = [erroremptyinput];

     setMessages({ ...messages, errorsEmail: emailErrors, errorsOTP: otpErrors });

     //clean inputs if there is an error
     if (otpErrors?.length != 0) {
         otpInput.current = '';
     }
     if (emailErrors?.length != 0) {
        emailInput.current = '';
    }

     if (otpErrors?.length || emailErrors?.length) {
         alertMessage((t('Invalid format. Please check your inputs and try again.')), 'Error');

    } else {
        verifyOtpandChangeUsername(user?.userId, emailInput.current, code, lang)
        .then((res) => {
            if ( res?.data?.error && !isNaN(res?.error) ){
                if ( res.error == 409 ) alertMessage((t('User already exists in the database. Please use a different username')), 'Error');
                // else alertMessage(t('Unknown error occured. Please try again later'), 'Error');
                else window.location.assign(`${clients['erised']}/${lang}/change-username-success`)
            }
            else if ( res?.data?.verified ) window.location.assign(`${clients['erised']}/${lang}/change-username-success`)
            else alertMessage((t('Wrong OTP. Please check your input and try again.')), 'Error');
        })
        .catch((err) => {})
    }
 }

    useEffect(() => {
        get_user();
    }, []);
    // console.log(user)
  return (
    <PageForty>
        <Helmet>
            <title>Doitforme | Update username</title>
        </Helmet>
            <Alert_
                messages={alerts}
            />
            <PageAlwaysVisible>
                <PageTitle>
                    <h1>{t('Update username')}</h1>
                   
                </PageTitle>
                <PageSubtitle>
                    <p>{t("As part of our evolving authenticating policy, we have made a significant change, we now accept email addresses as usernames instead of phone numbers. We apologize for any inconvenience this may cause, but we believe it will enhance the security and usability of our platform.") }</p>
                    <p>{t("Please note that this code will only grant you access to your account for the first login attempt. Subsequently, you will be prompted to set up a personalized code of your choice.")}</p>
                </PageSubtitle>
                <Form>
                    <Builder_
                        noPads
                        title={t('Enter your email')}
                    >
                    <Input_
                        placeholder={t('Email')}
                        required
                        onChange={handleEmail}
                        errors={messages?.errorsEmail}
                        helpers={messages?.helpersEmail}
                        successes={messages?.successesEmail}
                        value={emailInput?.current}
                        defaultValue={emailInput?.current}
                       
                    />
                    </Builder_>
                    <Builder_
                        noPads
                        title={t('Verify your mobile by typing the 6-digit code')}
                        last
                    >
                        <Input_
                            placeholder={t('OTP')}
                            required
                            onChange={handleOTP}
                            errors={messages?.errorsOTP}
                            helpers={messages?.helpersOTP}
                            successes={messages?.successesOTP}
                            value={otpInput?.current}
                            defaultValue={otpInput?.current}
                        />
                    </Builder_>
                    <Actions_ end>
                        <Main_
                            text={t("Next")}
                            iconRight="Next"
                            onClick={send_Verification_Code}
                        />
                    </Actions_>
                </Form>
            </PageAlwaysVisible>
            <PageDesktopOnly>
                <PageIllu>
                    <img src="https://genius1071.friktoriaservers.net/doitforme/morning/app/erised/Password.svg" />
                </PageIllu>
            </PageDesktopOnly>
        </PageForty>
  )
}
