import React, {useEffect,useContext} from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from '../../services/authentication/Guard'
import querystring from 'query-string'

function LoginTunnel() {

    const auth = useContext(AuthContext);

    const {lang, client} = useParams();
    const redirectUrl = window.location.search
    useEffect(() => {
            auth.login(lang, client, redirectUrl.replace('?redirectUrl=',''))
    },[])
}

export default LoginTunnel