import {
    Actions_,
    AlertContext,
    Alert_,
    Builder_,
    Dropdown_,
    Form,
    Grey_Link,
    Main_,
    PageAlwaysVisible,
    PageDesktopOnly,
    PageSixty,
    PageTitle,
    PageToolsSixty,
    countries,
} from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Header_ from '../../components/Header_';
import Aside_ from '../../components/navigation/Aside_';
import store from '../../redux/store';
import { edit_settings, get_settings } from '../../services/api/Users';
import { update_user_in_cache } from '../../services/authentication/SessionService';
import { update_options_cookie } from '../../helpers/initMenuOptions';
import { Helmet } from 'react-helmet';

export default function PREFERENCES() {

    const navigate = useNavigate();
    
    const id = store.getState().user?.user?.sub;
    // const preferences = store.getState()?.user?.user?.preferences;
    const preferences = {
        theme: store.getState().theme,
        language: store.getState().selectedLanguage,
        region: store.getState().country
    }

    const [userPreferences, setUserPreferences] = useState({
        userId: id,
        theme: preferences?.theme ? preferences.theme : 'light',
        language: preferences?.language ? preferences.language : 'el',
        region: preferences?.region ? preferences.region : 'Greece',
    });
    // const [language, setLanguage] = useState("Language");
    // const [region, setRegion] = useState("Region");
    const { t } = useTranslation();
    const { alerts, setAlerts } = useContext(AlertContext);

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    const saveSettings = () => {

        if (
            !userPreferences?.theme || 
            !userPreferences?.language || 
            !userPreferences?.region
        ) {
            alertMessage(
                (t('You need to select an option to update the preferences')),
                'Error'
            );
            return;
        };

        const userId = store.getState()?.user?.user?.sub;
        store.dispatch({type: 'THEME_UPDATE', payload: userPreferences.theme})
        store.dispatch({type: 'LANGUAGE_UPDATE', payload: userPreferences.language})
        store.dispatch({type: 'COUNTRY_UPDATE', payload: userPreferences.region})
        if ( 
            userPreferences.theme != store.getState().user?.user?.preferences?.theme ||  
            userPreferences.language != store.getState().user?.user?.preferences?.language ||  
            userPreferences.region != store.getState().user?.user?.preferences?.region 
        ) update_options_cookie(userId)
        else  {
            alertMessage(
                (t('Your preferences are already saved')),
                'Loading'
            );
            return;
        };

        alertMessage(t('Your changes have been saved!'), 'Success');

        update_user_in_cache({
            language: userPreferences.language,
            region: userPreferences.region,
            theme: userPreferences.theme
        });
        
    };

    return (
        <PageSixty>
            <Helmet>
                <title>Doitforme | User preferences</title>
            </Helmet>
            <Alert_ messages={alerts} />
            <PageAlwaysVisible>
                <PageTitle>
                    <Grey_Link
                        iconLeft="Back"
                        noPadding
                        onClick={() => navigate(`/${userPreferences.language}/settings`)}
                    />
                    <h1>Preferences</h1>
                </PageTitle>
                <Header_ />
                <Form>
                    <Builder_
                        title={t('Select the theme you prefer')}
                        noPads
                    >
                        <Dropdown_>
                            <select
                                onChange={(event) =>
                                    setUserPreferences({
                                        ...userPreferences,
                                        theme: event.target.value,
                                    })
                                }
                                value={userPreferences.theme}
                            >
                                <option  value="" selected>{t('Theme')}</option>
                                <option value='light'>{t('Light')}</option>
                                <option value='dark'>{t('Dark')}</option>
                            </select>
                        </Dropdown_>
                    </Builder_>
                    <Builder_
                        title={t('Select the language you prefer')}
                        noPads
                    >
                        <Dropdown_>
                            <select
                                onChange={(event) =>
                                    setUserPreferences({
                                        ...userPreferences,
                                        language: event.target.value,
                                    })
                                }
                                value={userPreferences.language}
                            >
                                <option value="" selected>{t('Language')}</option>
                                <option value='el'>{t('Greek')}</option>
                                <option value='en'>{t('English')}</option>
                                <option value='de'>{t('German')}</option>
                            </select>
                        </Dropdown_>
                    </Builder_>
                    <Builder_ title={t('Select your region')} noPads>
                        <Dropdown_>
                            <select
                                onChange={(event) =>
                                    setUserPreferences({
                                        ...userPreferences,
                                        region: event.target.value,
                                    })
                                }
                                value={userPreferences.region}
                            >
                                <option  value="" selected>{t('Region')}</option>
                                {countries.map((country, i) => {
                                    return (
                                        <option value={country.country} key={i}>
                                            {country.country}
                                        </option>
                                    );
                                })}
                            </select>
                        </Dropdown_>
                    </Builder_>
                </Form>
                <Actions_ end={true}>
                    <Main_ onClick={() => saveSettings()} text={t('Save')} />
                </Actions_>
            </PageAlwaysVisible>
            <PageDesktopOnly></PageDesktopOnly>
        </PageSixty>
    );
}
