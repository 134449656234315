import { Single_ } from 'monica-alexandria';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { Helmet } from 'react-helmet';

export default function NEW_PASSWORD() {
    const {t} = useTranslation();
    const lang = i18n.language ? i18n.language : 'en';
    const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Doitforme | User password set</title>
      </Helmet>
      <Single_
          backgroundImage={"https://genius1071.friktoriaservers.net/doitforme/morning/app/griphook/NoAccess.svg"}
          title={t("Your password has been successfully set.")}
          onClickMain = {() => navigate(`/${lang}`)}
          textMain = {t("Back to my account")}
      />
    </>
  )
}
