import { Single_ } from 'monica-alexandria';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { Helmet } from 'react-helmet';

export default function SUCCESS_RESET() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const lang = i18n.language ? i18n.language : 'en';
 
  return (
    <>
      <Helmet>
        <title>Doitforme | User password updated</title>
      </Helmet>
      <Single_
        backgroundImage={"https://genius1071.friktoriaservers.net/doitforme/morning/app/griphook/NoAccess.svg"}
        title={t("Your password has been successfully reset!")}
        onClickMain = {() => navigate(`/${lang}`)}
        textMain = {t("Back to my account")}
      />
    </>
  )
}
