import React, { useEffect, useContext, useState } from 'react';
import {
    ThemeContext,
    HamContext,
    LangContext,
    MenuContext,
    Nav_,
    logo,
} from 'monica-alexandria';
import { AuthContext } from '../../services/authentication/Guard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { checkPermissions } from '../../helpers/checkPermissions';
import store from '../../redux/store';
import { get_user_images } from '../../services/api/Users';
import { update_options_cookie } from '../../helpers/initMenuOptions';
import { config } from '../../config/index';

const clients = require('../../config/index').config;
const adminClient = process.env.REACT_APP_ADMIN_CLIENT;
const paymentsclient = process.env.REACT_APP_PAYMENTS_CLIENT;
const authClient = process.env.REACT_APP_AUTH_CLIENT;

const userId = store.getState()?.user?.user?.sub;

const Navbar_ = () => {
    const { t } = useTranslation();
    const minioUrl = clients['minio'];

    const [hamState, setHamState] = useState(false);
    const { setActiveMenu } = useContext(MenuContext);
    const { setLanguage } = useContext(LangContext);
    const { theme } = useContext(ThemeContext);
    const [app, major, minor] = process.env.REACT_APP_VERSION.split('.').map(Number);

    const [image, setImage] = useState(
        minioUrl + '/doitforme/morning/app/boiler/DefaultAvatar.jpg'
    );


    const renderImage = () => {
        const userId = store.getState()?.user?.user?.sub;

        get_user_images(userId)
            .then((res) => {
                if (res?.data?.data?.images?.avatar != 'set') {
                    setImage(
                        minioUrl +
                            '/doitforme/morning/app/boiler/DefaultAvatar.jpg' +
                            '?v=' +
                            Math.random().toString(36).substring(2, 22)
                    );
                } else {
                    const imageVersion = Math.random()
                        .toString(36)
                        .substring(2, 22);
                    setImage(
                        minioUrl +
                            '/erisedev/' +
                            userId +
                            '/avatar.png' +
                            '?v=' +
                            imageVersion
                    );
                }
            })
            .catch((err) => {});
    };

    const lang = i18n.language ? i18n.language : 'en';

    const [textCta, setTextCta] = useState('');
    let navigate = useNavigate();
    const changeLanguage = (lang) => {
        let path = window.location.pathname.split('/');
        path[1] = lang;
        let newPath = path.join('/');
        i18n.changeLanguage(lang);
        navigate(newPath);
        setLanguage(lang);
        setActiveMenu('Main');
    };

    const auth = useContext(AuthContext);
    const permission = checkPermissions('Knight', auth.user.roles);

    const getTextCta = () => {
        if (!auth.authenticated) {
            setTextCta('Become a doer');
        } else if (!permission) {
            setTextCta('Buy tools');
        } else if (permission) {
            setTextCta('Admin');
        }
    };

    const navigateCta = () => {
        if (!auth.authenticated) {
            navigate(auth.login());
        } else if (!permission) {
            navigate(`${lang}/all-tools`);
        } else if (permission) {
            window.location.assign(`${clients['akihiko']}/${lang}`);
        }
    };

    const logoCLick = () => {
        if (!auth.authenticated) {
            auth?.login();
        } else {
            window.location.assign(`${clients[authClient]}/${lang}`);
        }
    };

    const settingsClick = () => {
        navigate(`${lang}/settings`);
    };

    const avatarClick = () => {
        navigate(`${lang}`);
    };

    useEffect(() => {
        const userId = store.getState()?.user?.user?.sub;
        store.dispatch({ type: 'THEME_UPDATE', payload: theme });
        if (theme != store.getState().user?.user?.preferences?.theme)
            update_options_cookie(userId);
    }, [theme]);

    useEffect(() => {
        getTextCta();
        if (auth.authenticated) renderImage();
    }, []);


    return (
        <HamContext.Provider value={{ hamState, setHamState }}>
            <Nav_
                // home
                // onHome ={() => navigate(`/${lang}/`)}
                iconHome = {logo.erised[theme]}
                textHome = 'Portal'
                minorV={minor}
                majorV={major}
                onClick_Logo={logoCLick}
                firstname_Account={auth?.user?.firstname}
                imgAlt_Account="Text"
                img_Account={image}
                onClick_Logout={() => auth?.logout()}
                onClick_Account={avatarClick}
                onClick_Login={() => auth?.login(authClient)}
                onClick_Settings_Main={settingsClick}
                role={
                    permission
                        ? 'Admin'
                        : auth?.authenticated
                        ? 'Protected'
                        : 'Unprotected'
                }
                onClick_Admin_Main = {()=> navigate(`/${lang}/akihiko/erised`)}
                text_Admin_Main={t('Admin')}
                onClick_Subscriptions_Main= {()=>window.location.assign(`${clients.griphook}/${lang}`)}
                text_Subscriptions_Main={t('Subscriptions')}
                text_Login={t('Join')}
                text_Logout={t('Logout')}
                text_Settings_Main={t('Settings')}
                text_Theme_Main={t('Theme')}
                text_Title_Language={t('Language')}
                text_Title_Main={t('Menu')}
                text_Title_Region={t('Region')}
                onClick_English_Language={() => changeLanguage('en')}
                onClick_German_Language={() => changeLanguage('de')}
                onClick_Greek_Language={() => changeLanguage('el')}
                text_Apps = {t("Apps")}
                text_All_Title = {t("Doitforme applications")}
                onClick_All_Dailyprofit = {() => window.location.assign(config.dailyprofit)}
                onClick_All_Lois = {() => window.location.assign(config.lois)}
                onClick_All_Whitecastle = {() => window.location.assign(config.whitecastle)}
                onClick_All_Syrax = {() => window.location.assign(config.syrax)}
            />
        </HamContext.Provider>
    );
};

export default Navbar_;
