/* Initial state of store */


export const initialState = {
  selectedLanguage: 'el',
  country:'GR',
  theme:'Light',
  user: {}
}

export function rootReducer(state = initialState, action) {
  const payload = action.payload;

  switch (action.type) {
    case 'LANGUAGE_UPDATE': {
      return { ...state, selectedLanguage: payload };
    }
    case 'COUNTRY_UPDATE': {
      return { ...state, country: payload };
    }
    case 'THEME_UPDATE': {
      return { ...state, theme: payload };
    }
    case 'KEYCLOAK': {
      return { ...state, user: payload };
    }
    case 'USER_UPDATE': {
      return { ...state, user: payload };
    }
    case 'SAVE_TRANSLATIONS': {
      return { ...state, translations: payload };
    }
    default: return state;
  }
}

export default rootReducer;
