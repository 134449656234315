import React from 'react'
import { Single_ } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from '../../localization/i18next';
import { Helmet } from 'react-helmet';

export default function CHANGE_USERNAME_SUCCESS() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const lang = i18n.language ? i18n.language : 'en';
  return (
    <>
      <Helmet>
        <title>Doitforme | Successful username change</title>
      </Helmet>
      <Single_
        backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/erised/keyParachute.svg"
        iconGrey="Rocket"
        iconMain="Rocket"
        onClickMain= {() => navigate(`/${lang}/auth`)}
        text={t("Check your email inbox for a message from us. It contains a temporary password for your first login attempt. If you haven't received the email, please check your spam folder or wait a few moments for it to arrive.")}
        textMain={t("Login")}
        title={t("Username Updated Successfully")}
      />
    </>
    
  )
}
