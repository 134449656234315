import {
    Builder_,
    CardsForty,
    CardsFull,
    CardsSixty,
    On_Stat,
    Section_Title,
    Slider_,
    Stat_,
    Sub_Card,
    TBody,
    TD,
    TH,
    THead,
    TR,
    TRH,
    Table,
    TableDesktop,
    Textarea_,
} from 'monica-alexandria';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { update_user } from '../../../../services/api/Users';
import { useTranslation } from 'react-i18next';

const UserInfo = styled.div``;

const UserInfoHeader = styled.div`
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr;
    align-items: center;
    gap: 1rem;
`;

const UserInfoAvatar = styled.div`
    height: 10rem;
    width: 10rem;
    overflow: hidden;
    border-radius: 50%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
`;

export const User_Info = (props) => {
    const [user,setUser] = useState(props.activeUser);
    const navigate = useNavigate();
    const { t } = useTranslation();
    

  //  const { alerts, setAlerts } = useContext(AlertContext);

    const updateUser = () => {
        let updatedUserInfo = JSON.parse(JSON.stringify(user))
        delete updatedUserInfo.subscriptions;

        update_user(updatedUserInfo)
        .then(res => {
           // setUsers(data); 
            if (props.notify) props.notify(user);
            props.setAlertMessage('Your changes have been saved!','Success');
          })
          .catch(error => {
            props.setAlertMessage(
                t('There was an error, please try again later'),'Error');
          });
        props.toggleSlider(false);
    }
    return (
        <UserInfo>
            <Slider_ 
                title="Userid" 
                text={t("Apply")}
                onClose = {() => props.toggleSlider(false)}
                onClick = {() => updateUser()}
            >
            <On_Stat 
                text={user?.userId!=='false' ? `${user.firstname} ${user.lastname}` : `${user?.name}`} 
                title={user?.userId!=='false' ? t("View user") : ""}
                variant="" 
                // TODO: Make it dynamic
                onClick = {()=>{user?.userId!=='false' && window.open(`http://localhost:5600/el/akihiko/erised/users/${user?.userId}`)}} 
            />
            <Section_Title text={t("Information")} />
                <Table>
                    <TBody>
                        <TR>
                            <TD>{t("User id")}</TD>
                            <TD>{user?.userId}</TD>
                        </TR>
                        <TR>
                            <TD>{t("Username")}</TD>
                            <TD>{user?.username}</TD>
                        </TR>
                        {user?.userId !=='false' ? 
                            user?.emails?.map((email) =>(
                                <TR>
                                    <TD>{t("Email")}</TD>
                                    <TD>{email?.email}</TD>
                                </TR>
                            )) 
                        : 
                            <TR>
                                <TD>{t("Email")}</TD>
                                <TD>{user?.email}</TD>
                            </TR>
                        }
                        {user?.vatNumbers?.map((vat) =>(
                            <TR>
                                <TD>{t("VAT number")}</TD>
                                <TD>{vat}</TD>
                            </TR>
                        ))}
                        <TR>
                            <TD>{t("First name")}</TD>
                            <TD>{user?.userId!=='false' ? user?.firstname : user?.name?.split(' ')[0]}</TD>
                        </TR>
                        <TR>
                            <TD>{t("Last name")}</TD>
                            <TD>{user?.userId!=='false'? user?.lastname : user?.name?.split(' ')[1]}</TD>
                        </TR>
                        {user.gender ?
                            <TR>
                                <TD>{t("Sex")}</TD>
                                <TD>{user?.gender}</TD>
                            </TR>
                        :
                            null
                        }
                        <TR>
                            <TD>{t("Created")}</TD>
                            <TD>{new Date(user.createdAt).toLocaleDateString()}</TD>
                        </TR>
                    </TBody>
                </Table>
            <Section_Title text={t("Subscriptions")} />
            <CardsFull>
                {/* {user?.subscriptions?.map((sub)=>(
                    <Subscription_Card status={sub?.status} statusText={sub?.status} planName={sub?.plan_name} />
                ))} */}
                <Sub_Card lois active />
                <Sub_Card dailyprofit />
                <Sub_Card />
            </CardsFull>
            {user?.userId!=='false' &&  <Section_Title text={t("Comments")} /> }
            {user?.userId!=='false' &&  <Builder_>
                <Textarea_
                        placeholder={t("Add any comments about this user here")}
                        defaultValue = {user?.comments}
                        rows="8"
                        onChange={(e) => setUser({...user, comments: e.target.value})}
                    /> 
                </Builder_>
            }
            </Slider_>
        </UserInfo>
    );
};
