import {
    Main_,
    Slider_,
    Builder_,
    Upload_Cover,
    Upload_Avatar,
} from 'monica-alexandria';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import store from '../redux/store';
import { get_user_images } from '../services/api/Users';
import E_Upload_Avatar from './upload/E_Upload_Avatar';
import E_Upload_Cover from './upload/E_Upload_Cover';
const clients = require('../config/index').config;

const Header = styled.div`
    width: 100%;
    margin-bottom: 9rem;
    position: relative;
`;

const HeaderEdit = styled.div`
    position: absolute;
    right: 1rem;
    top: 1rem;
`;

const HeaderCover = styled.div`
    img {
        object-fit: cover;
        height: 100%;
        min-height: 20rem;
        width: 100%;
        aspect-ratio: 4/1;
        border-radius: var(--smallRadius);
    }
`;
const HeaderAvatar = styled.div`
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, 5rem);
    border: 2px solid ${(p) => p.theme.background};

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
`;

export default function Header_(props) {
    const { t } = useTranslation();

    const user = store.getState().user;
    const userId = user?.user?.sub;
    const minioUrl = clients['minio'];

    const [avatarError, setAvatarError] = useState(false);
    const [coverError, setCoverError] = useState(false);
    const [slider, toggleSlider] = useState(false);

    const [images, setImages] = useState({
        avatar: '',
        cover: '',
        uploaded_avatar: false,
        uploaded_cover: false
    });

    // Image version is used for disabling caching of images
    const renderImage = (fileName, type) => {
        if (images[type] == 'set') {
            const imageVersion = Math.random().toString(36).substring(2, 22);
            return (
                minioUrl +
                '/erisedev/' +
                userId +
                '/' +
                fileName +
                '?v=' +
                imageVersion
            );
        } else if (images[type] == 'default') {
            if (type == 'avatar')
                return `${minioUrl}/doitforme/morning/app/boiler/DefaultAvatar.jpg`;
            else
                return `${minioUrl}/doitforme/morning/app/boiler/DefaultCover.jpg`;
        } else return;
    };

    const getImageState = () => {
        if (user?.user.images?.avatar && user?.user.images?.cover) {
            setImages({
                ...images,
                avatar: user?.user?.images.avatar,
                cover: user?.user?.images.cover,
            });
        } else {
            fetchUserImages();
        }
    };

    const fetchUserImages = () => {
        get_user_images(userId)
            .then((res) => {
                store.dispatch({
                    type: 'USER_UPDATE',
                    payload: {
                        ...user,
                        user: { ...user?.user, images: images },
                    },
                });
                setImages({
                    ...images,
                    avatar: res?.data?.data?.images?.avatar,
                    cover: res?.data?.data?.images?.cover,
                });
            })
            .then((err) => {});
    };
    

    useEffect(() => {
        if (props.uploading) {
            setAvatarError(false);
            setCoverError(false);
        }
    }, [props.uploading]);

    useEffect(() => {
        getImageState();
    }, [user]);



    return (
        <Header>
            {slider ? (
                <Slider_
                    title={t('Media files')}
                    text={t('Close')}
                    onClick={() => toggleSlider(false)}
                    onClose={() => toggleSlider(false)}
                >
                    <Builder_ >
                        <E_Upload_Cover
                            getFilesFromChild = {() => {}}
                        />
                    </Builder_>
                    <Builder_ >
                        <E_Upload_Avatar 
                            getFilesFromChild = {() => {}}
                        />
                    </Builder_>
                </Slider_>
            ) : null}
            <HeaderEdit>
                <Main_
                    iconRight="Edit"
                    text={t('Edit')}
                    onClick={() => toggleSlider(true)}
                />
            </HeaderEdit>
            <HeaderCover>
                <img
                    // onError={() =>
                    //     setCoverError(
                    //         `${minioUrl}/doitforme/morning/app/boiler/DefaultCover.jpg`
                    //     )
                    // }
                    src={
                        coverError
                            ? coverError
                            : renderImage('cover.png', 'cover')
                    }
                />
            </HeaderCover>
            <HeaderAvatar>
                <img
                    // onError={() =>
                    //     setAvatarError(
                    //         `${minioUrl}/doitforme/morning/app/boiler/DefaultAvatar.jpg`
                    //     )
                    // }
                    src={
                        avatarError
                            ? avatarError
                            : renderImage('avatar.png', 'avatar')
                    }
                />
            </HeaderAvatar>
        </Header>
    );
}
