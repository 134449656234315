import {
    Actions_,
    AlertContext,
    Alert_,
    Builder_,
    Card,
    CardsForty,
    CardsFull,
    Confirmation_,
    Dropdown_,
    Filters_,
    Form,
    Grey_,
    Grey_Link,
    Input_,
    Input_Phone,
    Input_Phone_Register,
    Main_,
    Main_Plain,
    On_Click_Card,
    PageAlwaysVisible,
    PageDesktopPadding,
    PageFull,
    PageSixty,
    PageTitle,
    PageToolsForty,
    PageToolsSixty,
    Red_,
    Section_Title,
    Stat_,
    Sub_Card,
    TBody,
    TD,
    THead,
    TR,
    TRH,
    Table,
    TableDesktop,
    Textarea_,
    countries,
} from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import Header_ from '../../../components/Header_';
import Toolbar_ from '../../partials/navigation/Toolbar_';
import { Subscription_Card } from '../../partials/cards/Subscription_Card';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import store from '../../../redux/store';
import _ from 'lodash';
import { adminDeleteUser, edit_settings, get_my_subscriptions, get_settings, get_subscription } from '../../../services/api/Users';
import { update_user_in_cache } from '../../../services/authentication/SessionService';
import { validateEmail, validatePhone, validateVAT } from '../../../helpers/validators';
import { addEmail, addPhone, addVAT, dltEmail } from './helpers';
import styled from 'styled-components';
import { checkPermissions } from '../../../helpers/checkPermissions';
import { AuthContext } from '../../../services/authentication/Guard';

const UserSub = styled(Card)`
    padding: 2rem;
`

const UserSubTop = styled.div``

const UserSubLogo = styled.div`
    width: 3rem;

    img{
        width: 100%;
    }
`

const UserSubStatus = styled.div``

const UserSubDescription = styled.div``

export default function USER() {

        const navigate = useNavigate();
        const { t } = useTranslation();
        const userId = store.getState().user?.user?.sub;
        const lang = store.getState().selectedLanguage;

        const { id } = useParams();

        const auth = useContext(AuthContext);
        const permission = checkPermissions('Baron', auth?.user?.roles);
        console.log('PERMISSION', permission);

        const [currentPhone, setCurrentPhone] = useState('');
        const [currentEmail, setCurrentEmail] = useState('');
        const [currentVAT, setCurrentVAT] = useState('');
        const [mySubscriptions, setMySubscriptions] = useState([]);
        const { alerts, setAlerts } = useContext(AlertContext);
        const [editMode, ToggleEditMode] = useState(false);

        const [confirm, setConfirm] = useState(false)

        const [messages, setMessages] = useState({
            errorsPhone: [],
            helpersPhone: [],
            successesPhone: [],
            errorsEmail: [],
            helpersEmail: [],
            successesEmail: [],
            errorsVAT: [],
            helpersVAT: [],
            successesVAT: [],
        });
        
        const [userInfo, setUserInfo] = useState({
            userId: id,
            firstname: '',
            lastname: '',
            gender: (t('Gender')),
            phone: '',
            comments: '',
            emailList: [],
            phoneList: [],
            vatNumbers: [],
            verified: [],
         
        });

        function addPhone() {
            let phoneErrors = [];
            const phoneExists = userInfo?.phoneList?.includes(currentPhone);
    
            if (messages.helpersPhone?.length !== 0)
                phoneErrors = messages.helpersPhone;
    
            setMessages({ ...messages, errorsPhone: phoneErrors });
    
            if (phoneErrors.length != 0) {
                setCurrentPhone('');
                alertMessage(
                    (t('Invalid phone, please check your input and try again...')),
                    'Error'
                );
            } else if (phoneExists) {
                setCurrentPhone('');
                alertMessage(
                    t(
                        'Phone already exists, please check your input and try again...'
                    ),
                    'Error'
                );
                setMessages({
                    ...messages,
                    successesPhone: [],
                    helpersPhone: [],
                    errorsPhone: [],
                });
            } else {
                setUserInfo({
                    ...userInfo,
                    phoneList: [...userInfo.phoneList, currentPhone],
                });
                setCurrentPhone('');
                setMessages({
                    ...messages,
                    successesPhone: [],
                    helpersPhone: [],
                    errorsPhone: [],
                });
                alertMessage(
                    (t('Your phone has been successfully added ')),
                    'Success'
                );
            }
        }
        
        //get user info from db 
        const getSettings = () => {
            get_settings(id)
                .then((data) => {
    
                    let emailList = [];
                    let verified = data?.data?.emails;
                    if (
                        Array.isArray(data?.data?.emails) &&
                        typeof data?.data?.emails[0] === 'object'
                    ) {
                        data?.data?.emails?.forEach((element) => {
                            emailList.push(element.email);
                        });
    
                    } else emailList = data?.data?.emails ? data?.data?.emails : [];
                     // let phones = [...data?.data?.phones];
     
                    setUserInfo({
                        ...userInfo,
                        firstname: data?.data?.firstname
                            ? data?.data?.firstname
                            : '',
                        lastname: data?.data?.lastname ? data?.data?.lastname : '',
                        gender: data?.data?.gender ? data?.data?.gender : 'Other',
                        phone: data?.data?.username ? data.data.username : '',
                        comments: data?.data?.comments ? data?.data?.comments : '',
                        phoneList: data?.data?.phones ? data.data.phones : '',
                        emailList: emailList,
                        vatNumbers: data?.data?.vatNumbers ? data.data.vatNumbers : '',
                        verified: verified,
                    });
                })
                .catch((err) => {});
        };

        const getSubscription = () => {
            get_subscription(id)
            .then(res => {})
            .catch((err) => {})
        }
        // console.log('SUBS', mySubscriptions);
        // const getSubInfo = () => {
        //     getSubInfo(userId)
        //     .then(res => console.log(res.data))
        //     .catch((err) => console.log('err', err))
        // }

        const validatePhoneFormat = (e) => {
            let dataPhone = validatePhone(e.target.value, t);
    
            setMessages({
                ...messages,
                helpersPhone: dataPhone.helper,
                successesPhone: dataPhone.success,
            });
            setCurrentPhone(e.target.value);
        };

        const validateEmailFormat = (e) => {
            let dataEmail = validateEmail(e.target.value, t);
    
            setMessages({
                ...messages,
                helpersEmail: dataEmail.helperemail,
                successesEmail: dataEmail.successemail,
            });
            setCurrentEmail(e.target.value);
        };

        const validateVATFormat = (e) => {
            let dataVAT = validateVAT(e.target.value, t);
    
            setMessages({
                ...messages,
                helpersVAT: dataVAT.helpervat,
                successesVAT: dataVAT.successvat,
            });
            setCurrentVAT(e.target.value);
        };

      
        // const dltPhone = (phone) => {
        //     setUserInfo((prevState) => {
        //         return {
        //             ...prevState,
        //             phoneList: prevState.phoneList.filter((_, i) => i !== phone),
        //         };
        //     });
        // };
        
        const dltPhone = (phone) => {
            setUserInfo((prevState) => {
                return {
                    ...prevState,
                    phoneList: prevState.phoneList.filter((_, i) => i !== phone),
                };
            });
        };
        // const dltEmail = (email) => {
        //     let newVerified = JSON.parse(JSON.stringify(userInfo.verified));
        //     newVerified.splice(1, 1);
            
        //     setUserInfo((prevState) => {
        //         return {
        //             ...prevState,
        //             emailList: prevState.emailList.filter((_, i) => i !== email),
        //             verified: newVerified,
        //         };
        //     });
        // };

        const dltVAT = (vat) => {
            setUserInfo((prevState) => {
                return {
                    ...prevState,
                    vatNumbers: prevState.vatNumbers.filter((_, i) => i !== vat),
                };
            });
        };
    
        const saveSettings = () => {
            let updatedUserInfo = JSON.parse(JSON.stringify(userInfo));
    
            updatedUserInfo.emails = userInfo.emailList;
            updatedUserInfo.phones = userInfo.phoneList;
            updatedUserInfo.vatNumbers = userInfo.vatNumbers;
            
            updatedUserInfo.emails = _.map(userInfo?.emailList, (email) => {
                return { verified: false, email: email };
            });
    
            edit_settings(updatedUserInfo)
                .then((data) => {
                    if (data) {
                        update_user_in_cache(updatedUserInfo);
                        alertMessage(t('Your changes have been saved!'), 'Success');
                    }
                })
                .catch((err) => {
                    alertMessage(
                        (t('There was an error, please try again later')),
                        'Error'
                    );
                });
        };

        const alertMessage = (message, status) => {
            setAlerts([
                ...alerts,
                {
                    message: {
                        text: message,
                        status: status,
                    },
                },
            ]);
        };

        const handleConfirm = (action) => {
            if (action === 'delete') {
                adminDeleteUser(id)
                    .then((res) => {
                        if ( res ) {
                            navigate(`/${lang}/akihiko/erised/users`, {state: {alert: "Success", message: "User deleted"}})
                        } else {
                            alertMessage('Something went wrong','Error')
                        }
                    }).catch(err=>{});
            }
            setConfirm(false);
        };

        const handleConfirmMsg = () => {
            if (confirm === 'delete') {
                return (t('Are you sure you want to delete your account?'));
            }
        };

        useEffect(() => {
            getSettings();
            // getMySubscriptions();
            // getSubscription();
        }, []);


        console.log('USERINFO', userInfo);
    return (
        <PageFull>
             <Alert_
                messages={alerts}
            />
            {confirm && (
                <Confirmation_
                    message={handleConfirmMsg()}
                    no="No"
                    onNo={() => handleConfirm()}
                    onYes={() => handleConfirm(confirm)}
                    yes="Yes"
                />
            )}
            <PageTitle>
                <h1>{userInfo.firstname} {userInfo.lastname}</h1>
            </PageTitle>
            {permission ?
                editMode ?
                    <Filters_>
                        <Main_ text={t('Save')} size="small" iconRight="Save" onClick = {saveSettings}/>
                        <Red_ iconLeft="Delete" text={t("Delete")} noPadding size="small" onClick = {() => setConfirm('delete')}/>
                        <Main_Plain text={t('View mode')} size="small" iconRight="Edit" onClick={() => ToggleEditMode(!editMode)} />
                    </Filters_>
                :
                    <Filters_>
                        <Main_Plain text={t('Edit mode')} size="small" iconRight="Edit" onClick={() => ToggleEditMode(!editMode)} />
                    </Filters_>
                
            :null}
            <Section_Title text={t('User information')} />
            {editMode ?
                <Form>
                    <Builder_ split="2" title={t("Edit name")}>
                        <Input_
                            placeholder={t('First name')}
                            value={userInfo.firstname}
                            onChange={(event) =>
                                setUserInfo({
                                    ...userInfo,
                                    firstname: event.target.value,
                                })}
                        />
                        <Input_
                            placeholder={t('Last name')}
                            value={userInfo.lastname}
                            onChange={(event) =>
                                setUserInfo({
                                    ...userInfo,
                                    lastname: event.target.value,
                                })}
                        />
                    </Builder_>
                    <Builder_ title={t("Phone number")}>
                        <Input_Phone_Register 
                            // onChange={(event) =>
                            //     setUserInfo({
                            //         ...userInfo,
                            //         phoneList: event.target.value,
                            //     })} 
                            onChange={validatePhoneFormat}
                            placeholder={t('Phone number')}

                                onAdd={addPhone}
                                list={userInfo?.phoneList}
                                value={currentPhone}
                                add={t('Add')}
                                listIcon="Phone"
                        />
                    </Builder_>
                    <Builder_ title={t("Edit gender")}>
                        <Dropdown_>
                            <select      
                                onChange={(event) =>
                                setUserInfo({
                                    ...userInfo,
                                    gender: event.target.value,
                                })} 
                                value={userInfo.gender}>
                                <option>{t('Gender')}</option>
                                <option>{t('Male')}</option>
                                <option>{t('Female')}</option>
                                <option>{t('Other')}</option>
                            </select>
                        </Dropdown_>
                    </Builder_>
                    <Builder_ title={t("Edit region")}>
                        <Dropdown_>
                            <select>
                                <option>{t('Region')}</option>
                                {countries?.map((country, i) => {
                                    return (
                                        <option key={i}>
                                            {country.country}
                                        </option>
                                    );
                                })}
                            </select>
                        </Dropdown_>
                    </Builder_>
                    <Builder_ title={t('Comments')}>
                        <Textarea_
                            placeholder={t('Add any comments about this user here')}
                            defaultValue = {userInfo?.comments}
                            rows="8"
                            onChange={(e) => setUserInfo({...userInfo, comments: e.target.value})}
                        />
                    </Builder_>
                </Form>
            :
                <Table>
                    <TBody>
                        <TR>
                            <TD>{t("User id")}</TD>
                            <TD>{id}</TD>
                        </TR>
                        <TR>
                            <TD>{t("Username")}</TD>
                            <TD></TD>
                        </TR>
                        <TR>
                            <TD>{t("First name")}</TD>
                            <TD>{userInfo?.firstname}</TD>
                        </TR>
                        <TR>
                            <TD>{t("Last name")}</TD>
                            <TD>{userInfo?.lastname}</TD>
                        </TR>
                        <TR>
                            <TD>{t("Gener")}</TD>
                            <TD>{userInfo?.gender}</TD>
                        </TR>
                        <TR>
                            <TD>{t("Region")}</TD>
                            <TD></TD>
                        </TR>
                        <TR>
                            <TD>{t("Registered at")}</TD>
                            <TD></TD>
                        </TR>
                        {userInfo?.emailList?.length ?
                            userInfo?.emailList?.map((email, i) => 
                                <TR key={i}>
                                    <TD>{t("Email address")}</TD>
                                    <TD>{email}</TD>
                                </TR>
                            ) : null}
                        {userInfo?.vatNumbers?.length ?
                            userInfo?.vatNumbers?.map((vat, i) => 
                                <TR key={i}>
                                    <TD>{t("VAT number")}</TD>
                                    <TD>{vat}</TD>
                                </TR>
                            ) : null}
                    </TBody>
                </Table>
            }
            {editMode ?
                null
            :
                <p>{userInfo?.comments}</p>
            }
            <UserSub>

            </UserSub>
        </PageFull>
    );
}
