import { PageIllu } from "monica-alexandria";
import styled from "styled-components";

export const AuthIllu = styled(PageIllu)`

    img{
        width: 100%;
        max-width: 70rem;
    }
`

export const GdprSection = styled.div`
    margin: 2rem .5rem .5rem 0rem;
    
    a{
        color: var(--main);
    }
`