import { Single_ } from 'monica-alexandria';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../services/authentication/Guard';
import i18n from '../../localization/i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const protocol = process.env.REACT_APP_AUTH_PROTOCOL;
const url = process.env.REACT_APP_AUTH_HOST;
const port = process.env.REACT_APP_AUTH_PORT;
const realm = process.env.REACT_APP_REALM;
const lang = i18n.language ? i18n.language : 'en';

export default function ADD_PASS() {
    const navigate = useNavigate();
    const auth = useContext(AuthContext);
    const { t } = useTranslation();

    return (
        <>
        <Helmet>
            <title>Doitforme | Password not set</title>
        </Helmet>
        <Single_
            backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/erised/Password.svg"
            subtitle={t("Password not set")}
            text={t("Seems like you have not created a password for your account, click the button below to create one!")}
            title={t("Protect your account")}
            textMain={t("Create password")}
            onClickMain={() => auth.generate_password_link()}
            iconMain="Lock"
            textGrey={t("Skip for later")}
            onClickGrey={() => navigate(`/${lang}`)}
            iconGrey="Xicon"
        />
        </>
    );
}
