import axios from "axios"
import { config } from "../../config"

const delete_img = (userId, type) => {
  return new Promise((resolve, reject) => {

    axios.post(`${config.ERISED_BACKEND_URL}/media/users/deleteImg`, {
        data: {
            userId: userId,
            type: type
        }
    })
    .then((res) => {
        resolve(res)
    })
    .catch((err) => {
        resolve(false)
    });
  })
}

export {delete_img};