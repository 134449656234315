import { AlertContext, Alert_, Main_, Upload_Cover } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import store from '../../redux/store';
import { uploadPic } from '../../services/api/Upload';
import Upload_ from './Upload_';
import { delete_img } from '../../services/api/Media';
import { get_user_images } from '../../services/api/Users';
const clients = require('../../config/index').config;

const UploadCover = styled.div``;

const HeaderCover = styled.div`
    img {
        object-fit: cover;
        height: 100%;
        min-height: 20rem;
        width: 100%;
        aspect-ratio: 4/1;
        border-radius: var(--smallRadius);
    }
`;
const UploadWrapper = styled.div`
    padding-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 2rem;
`;

// for delete when alert is made
const Alert = styled.div`
    padding: 2rem;
    background-color: #f44336;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    z-index: 2000;
    span {
        color: white;
    }
`;
export default function E_Upload_Cover(props) {
    const { alerts, setAlerts } = useContext(AlertContext);

    const user = store.getState().user;
    const [coverError, setCoverError] = useState(false);
    const minioUrl = clients['minio'];
    const { t } = useTranslation();
    const userId = store.getState().user?.user?.sub;
    const [uploading, isUploading] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const [messages, setMessages] = useState({
        errors: [],
        successes: [],
    });

    const [images, setImages] = useState({
        avatar: user?.user?.images?.avatar,
        cover: user?.user?.images?.cover,
    });

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    // Image version is used for disabling caching of images
    const renderImage = (fileName, type) => {
        if (images[type] == 'set') {
            const imageVersion = Math.random().toString(36).substring(2, 22);
            return (
                minioUrl +
                '/erisedev/' +
                userId +
                '/' +
                fileName +
                '?v=' +
                imageVersion
            );
        } else if (images[type] == 'default') {
            if (type == 'avatar')
                return `${minioUrl}/doitforme/morning/app/boiler/DefaultAvatar.jpg`;
            else
                return `${minioUrl}/doitforme/morning/app/boiler/DefaultCover.jpg`;
        } else return;
    };

    const upload_pic = (files, type) => {
        // console.log('AAAAAAAAAA', files);
        // console.log('AAAAAAAAAA', type);
        if (files?.length && files[0]?.preview) {
            isUploading(true);
            alertMessage((t('Uploading cover...')), 'Loading');
            uploadPic(files[0], type, userId)
                .then((res) => {
                    store.dispatch({
                        type: 'USER_UPDATE',
                        payload: {
                            ...user,
                            user: {
                                ...user.user,
                                images: { avatar: images.avatar, cover: 'set' },
                            },
                        },
                    });
                    setImages({ ...images, cover: 'set' });
                    isUploading(false);
                    setMessages({
                        ...messages,
                        successes: [
                            (t('Your cover has been successfully uploaded!')),
                        ],
                        errors: [],
                    });
                })
                .catch((err) => isUploading(false));
        }
    };

    const delete_pic = () => {
        delete_img(userId, 'cover')
            .then((res) => {
                if (res?.data?.code == 200)
                    setMessages({
                        ...messages,
                        successes: [(t('Successfully deleted cover image.'))],
                        errors: [],
                    });
                else
                    setMessages({
                        ...messages,
                        errors: [(t('Something went wrong. Please try again.'))],
                        successes: [],
                    });
            })
            .catch((err) => {});
        setImages({ ...images, cover: 'default' });
        store.dispatch({
            type: 'USER_UPDATE',
            payload: {
                ...user,
                user: {
                    ...user.user,
                    images: { avatar: images.avatar, cover: 'default' },
                },
            },
        });
    };

    const handleBoomRejected = (type) => {
        // console.log('type', type);
        let reason = '';
        switch (type) {
            case 'videoSize': {
                reason = (t('Invalid video size'));
                break;
            }
            case 'fileMimetype': {
                reason = (t('File must be png or jpeg'));
                break;
            }
            case 'fileSize': {
                reason = (t('Invalid image size. Image must be less than 2MB'));
                break;
            }
        }
        setMessages({
            ...messages,
            errors: [(t('Could not upload asset. Reason')) + ': ' + reason],
            successes: [],
        });
    };

    useEffect(() => {
        if (images.cover) setImageSrc('');
        else
            setImageSrc(
                `${minioUrl}/doitforme/morning/app/boiler/DefaultCover.jpg`
            );
    }, [images]);

    useEffect(() => {
        setImages({
            ...images,
            avatar: user?.user?.images?.avatar,
            cover: user?.user?.images?.cover,
        });
    }, [user]);

    return (
        <UploadCover>
            <Alert_ messages={alerts} />
            {/* <HeaderCover>
            <img onError={() => setCoverError(`${minioUrl}/doitforme/morning/app/boiler/DefaultCover.jpg`)} 
                src={imageSrc  ? imageSrc : renderImage('cover.png', 'cover')} />
        </HeaderCover> */}
            <Upload_Cover
                accept="image/jpeg, image/png, image/jpg"
                boomRejected={(type) => handleBoomRejected(type)}
                delete={t("Delete")}
                errors={messages.errors}
                getFilesFromChild={(files) => upload_pic(files, 'cover')}
                helpers={[(t('Acceptable files png, jpg, jpeg, less than 2mb'))]}
                img={imageSrc ? imageSrc : renderImage('cover.png', 'cover')}
                onDelete={() => delete_pic()}
                onUpload="onClick event on Upload"
                successes={messages.successes}
                tips={[(t("Preferable ratio")) + ' 3:2']}
                title={t("Upload your cover image")}
                upload={t("Upload cover")}
                uploaded
                maxFiles="1"
                maxSize="2000000"
            />
        </UploadCover>
    );
}
