import axios from "axios"
import { config } from "../../config"


const getTickets = () =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.ERISED_BACKEND_URL}/tickets/getTickets`)
        .then(res => {
            resolve(res.data)})
        .catch((err) => {
            resolve(false);
        })
    })
}


export {
    getTickets, 
};
