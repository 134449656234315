export const validatePhone = (phone, t, region) => {
    let helper = [];
    let success = []
    if (!/^69/.test(phone)) {
      helper.push(t("Starts with 69"));
    }else{
      success.push(t("Starts with 69"));
    }
    if (!/^\d{10}$/.test(phone)) {
      helper.push(t("10 numbers"));
    }else{
      success.push(t("10 numbers"));
    }
    if (!/^\d+$/.test(phone)) {
      helper.push(t("Only numbers"));
    }else{
      success.push(t("Only numbers"));
    }
    return({
        helper,
        success
    })
}


// this is email validator
export const validateEmail = (email, t) => {

  let helperemail = [];
  let successemail = [];
  
  if(!/\S+@\S+\.\S{2,}/.test(email)) {
      helperemail.push((t('Correct email format')) +  " email@domain.com");
    } else if (/^\d+$/.test(email)) {
      helperemail.push(t('If you are registered with a phone number, please enter it here'));
    }else {
      successemail.push((t('Correct email format')) +  " email@domain.com");
    }

  return({
      helperemail,
      successemail
  })
}

export const validateLoginEmail = (email, t) => {

  let helperemail = [];
  let successemail = [];
  
     if (/^\d+$/.test(email)) {
      helperemail.push((t('If you are registered with a phone number, please enter it here')));
    } else if(!/\S+@\S+\.\S{2,}/.test(email)){
      helperemail.push((t('Correct email format')) +  " email@domain.com");
    }else {
      successemail.push((t('Correct email format')) +  " email@domain.com");
    }
  return({
      helperemail,
      successemail
  })
}

export const validateVAT = (vat, t) => {
  let helpervat = [];
  let successvat = [];

  if (!/^\d{9}$/.test(vat)) {
    helpervat.push(t("Invalid VAT format"));
  } else {
    successvat.push(t("Valid VAT format"));
  }

  return {
    helpervat,
    successvat,
  };
};


export const validateOTP = (otp, t) => {
  let helperOTP = [];
  let successOTP = [];

  // Case 1: Check if the input consists of only numbers
  if (!/^\d+$/.test(otp)) {
    helperOTP.push(t("Invalid OTP format. Only numbers are allowed."));
  } else {
    successOTP.push(t("Input contains only numbers."));
  }

  // Case 2: Check if the input consists of exactly six digits
  if (otp.length !== 6) {
    helperOTP.push(t("Invalid OTP format. Please enter a 6-digit number."));
  } else {
    successOTP.push(t("Input is a 6-digit number."));
  }

  return {
    helperOTP,
    successOTP,
  };
};