import store from '../redux/store'
import { availableRoles } from '../config/roles';

export const checkPermissions = (roleToCheck, userRoles) => {

    let hasAccess = false;
    
    if (!roleToCheck) return true;
    if (!userRoles) userRoles = store.getState()?.user?.user?.roles ?
                                store.getState().user.user.roles: [];

    userRoles.forEach(role => {
        if (availableRoles[role] && 
            availableRoles[role].includes(roleToCheck)) hasAccess = true;
    });

    return hasAccess;
}