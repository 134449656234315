import { On_Click_Card } from 'monica-alexandria';
import React from 'react';
import styled from 'styled-components';

const SubscriptionCard = styled.div`
    .active {
        div {
            background-image: radial-gradient(
                circle,
                var(--success) 30%,
                var(--white) 100%
            );
        }
        h6 {
            color: var(--success);
        }
    }
    .pending {
        div {
            background-image: radial-gradient(
                circle,
                var(--warning) 30%,
                var(--white) 100%
            );
        }
        h6 {
            color: var(--warning);
        }
    }
    .incomplete {
        div {
            background-image: radial-gradient(
                circle,
                var(--greyDark) 30%,
                var(--white) 100%
            );
        }
        h6 {
            color: var(--greyDark);
        }
    }
    .stopped {
        div {
            background-image: radial-gradient(
                circle,
                var(--error) 30%,
                var(--white) 100%
            );
        }
        h6 {
            color: var(--error);
        }
    }
`;

const SubscriptionCardStatus = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    div {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
    }
`;

export const Subscription_Card = (props) => {
    return (
        <SubscriptionCard>
            <On_Click_Card
                img="https://cdn.discordapp.com/attachments/1103751853541425184/1105435302996226048/Yousef_a_3D_square_with_rounded_edges_viewed_in_perspective_fea_87092665-68be-4139-91c2-e0f7c01b1d51.png"
                title={props?.planName}
            >
                <SubscriptionCardStatus className={props?.status}>
                    <div></div>
                    <h6>{props?.statusText}</h6>
                </SubscriptionCardStatus>
            </On_Click_Card>
        </SubscriptionCard>
    );
};
