import { AlertContext, Alert_, Main_, Upload_Avatar } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import store from '../../redux/store';
import { uploadPic } from '../../services/api/Upload';
import Upload_ from './Upload_';
import { delete_img } from '../../services/api/Media';
import { get_user_images } from '../../services/api/Users';
const clients = require('../../config/index').config;

const UploadAvatar = styled.div`
    display: flex;
    align-items: center;
    gap: 8rem;
`;
export default function E_Upload_Avatar() {
    const { alerts, setAlerts } = useContext(AlertContext);

    const user = store.getState().user;
    const minioUrl = clients['minio'];
    const { t } = useTranslation();
    const userId = store.getState().user?.user?.sub;
    const [uploading, isUploading] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const [messages, setMessages] = useState({
        errors: [],
        successes: [],
    });

    const [images, setImages] = useState({
        avatar: user?.user?.images?.avatar,
        cover: user?.user?.images?.cover,
    });

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    // Image version is used for disabling caching of images
    const renderImage = (fileName, type) => {
        if (images[type] == 'set') {
            const imageVersion = Math.random().toString(36).substring(2, 22);
            return (
                minioUrl +
                '/erisedev/' +
                userId +
                '/' +
                fileName +
                '?v=' +
                imageVersion
            );
        } else if (images[type] == 'default') {
            if (type == 'avatar')
                return `${minioUrl}/doitforme/morning/app/boiler/DefaultAvatar.jpg`;
            else
                return `${minioUrl}/doitforme/morning/app/boiler/DefaultCover.jpg`;
        } else return;
    };

    const upload_pic = (files, type) => {
        if (files?.length && files[0]?.preview) {
            isUploading(true);
            alertMessage((t('Uploading avatar...')), 'Loading');
            uploadPic(files[0], type, userId)
                .then((res) => {
                    store.dispatch({
                        type: 'USER_UPDATE',
                        payload: {
                            ...user,
                            user: {
                                ...user.user,
                                images: { cover: images.cover, avatar: 'set' },
                            },
                        },
                    });
                    setImages({ ...images, avatar: 'set' });
                    isUploading(false);
                    setMessages({
                        ...messages,
                        successes: [
                            (t('Your avatar has been successfully uploaded!')),
                        ],
                        errors: [],
                    });
                })
                .catch((err) => isUploading(false));
        }
    };

    const delete_pic = () => {
        delete_img(userId, 'avatar')
            .then((res) => {
                if (res?.data?.code == 200)
                    setMessages({
                        ...messages,
                        successes: [(t('Successfully deleted avatar img'))],
                        errors: [],
                    });
                else
                    setMessages({
                        ...messages,
                        errors: [(t('Something went wrong. Please try again.'))],
                        successes: [],
                    });
            })
            .catch((err) => {});
        setImages({ ...images, avatar: 'default' });
        store.dispatch({
            type: 'USER_UPDATE',
            payload: {
                ...user,
                user: {
                    ...user.user,
                    images: { cover: images.cover, avatar: 'default' },
                },
            },
        });
    };

    const handleBoomRejected = (type) => {
        let reason = '';
        switch (type) {
            case 'videoSize': {
                reason = (t('Invalid video size'));
                break;
            }
            case 'fileMimetype': {
                reason = (t('File must be png or jpeg'));
                break;
            }
            case 'fileSize': {
                reason = (t('Invalid image size. Image must be less than 2MB'));
                break;
            }
        }
        setMessages({
            ...messages,
            errors: [(t('Could not upload asset. Reason')) + ': ' + reason],
            successes: [],
        });
        // alertMessage (t("Could not upload asset. Reason")+": "+reason,'Error')
    };

    useEffect(() => {
        if (images.avatar) setImageSrc('');
        else
            setImageSrc(
                `${minioUrl}/doitforme/morning/app/boiler/DefaultAvatae.jpg`
            );
    }, [images]);

    useEffect(() => {
        setImages({
            ...images,
            avatar: user?.user?.images?.avatar,
            cover: user?.user?.images?.cover,
        });
    }, [user]);

    return (
        <UploadAvatar>
            <Alert_ messages={alerts} />
            {/* <HeaderAvatar>
                <img onError={() => setImageSrc(`${minioUrl}/doitforme/morning/app/boiler/DefaultAvatar.jpg`)} 
                    src={imageSrc ?  imageSrc : renderImage('avatar.png', 'avatar')} />
            </HeaderAvatar> */}
            {/* <Upload_ accept="image/jpeg, image/png, image/jpg"
                    general="Upload" 
                    files={fileAvatar}
                    type="cover" 
                    getFilesFromChild={(files) =>upload_pic(files, 'avatar')} 
                    boomRejected={(type) => handleBoomRejected(type)}
                    maxFiles="1" 
                    text={t('Upload avatar')}/> */}
            <Upload_Avatar
                accept="image/jpeg, image/png, image/jpg"
                boomRejected={(type) => handleBoomRejected(type)}
                delete={t("Delete")}
                errors={messages.errors}
                getFilesFromChild={(files) => upload_pic(files, 'avatar')}
                helpers={[(t('Acceptable files png, jpg, jpeg, less than 2mb'))]}
                iconDelete=""
                iconUpload=""
                img={imageSrc ? imageSrc : renderImage('avatar.png', 'avatar')}
                onDelete={() => delete_pic()}
                onUpload="onClick event on Upload"
                successes={messages.successes}
                tips={[(t("Preferable ratio")) + ' 1:1']}
                title={t("Upload your avatar image")}   
                upload={t("Upload avatar")}
                uploaded
                maxFiles="1"
                maxSize="2000000"
            />
        </UploadAvatar>
    );
}
