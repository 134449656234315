import axios from "axios"
import { config } from "../../config"
import store from "../../redux/store"


const check_user_exists = (phoneNumber) =>{
    return new Promise((resolve,reject) =>{
        axios.post(`${config.ERISED_BACKEND_URL}/users/checkUserWithPhone`,{
            phoneNumber
        })
        .then(res => resolve(res))
        .catch((err) => {
            resolve(false);
        })
    })
}

const update_user = (data) =>{
    return new Promise((resolve,reject) =>{
        axios.post(`${config.ERISED_BACKEND_URL}/users/user`,{
            data: data
        })
        .then(res => resolve(res))
        .catch((err) => {
            resolve(false);
        })
    })
}

const edit_settings = (data) =>{

    console.log('DARA', data);
    return new Promise((resolve,reject) =>{
        axios.post(`${config.ERISED_BACKEND_URL}/users/editSettings`,{
            data: data
        })
        .then(res => resolve(res))
        .catch((err) => {
            resolve(false);
        })
    })
}

const sort_users = (users, sorting) => {
    return new Promise((resolve, reject) => {
      axios.get(`${config.ERISED_BACKEND_URL}/users/sortUsers`, { users, sorting })
        .then((res) => resolve(res.data))
        .catch((err) => {
          resolve(false);
        });
    });
  };

const get_settings = (id) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.ERISED_BACKEND_URL}/users/${id}/getSettings`)
        .then(res => resolve(res))
        .catch((err) => {
            resolve(false);
        })
    })
}

const get_user_by_username = (username) =>{
    return new Promise((resolve,reject) =>{
        axios.get(`${config.ERISED_BACKEND_URL}/users/getUserByUsername?username=${username}`)
        .then(res => resolve(res))
        .catch((err) => {
            resolve(false);
        })
    })
}

const get_users = (options) => {
    return new Promise((resolve, reject) => {
       
        let params = `${options?.filters ? "filters="+encodeURIComponent(JSON.stringify(options.filters))+"&" : ""}`+
                        `${options?.elastic ? "elastic="+options.elastic+"&" : ""}`+
                        `${options?.sorting ? "sorting="+encodeURIComponent(JSON.stringify(options.sorting))+"&" : ""}`+
                        `${options?.pagination ? "pagination="+options.pagination+"&" : ""}`

      
        axios.get(`${config.ERISED_BACKEND_URL}/users/getAll?${params}`)
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
            })
    })
}

const activate_user = (userId) => {

    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/users/activateUser`, {
            userId
        }) 
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
            })
    })
}

const deactivate_user = (userId) => {

    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/users/deactivateUser`, {
            userId
        })
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
            })
    })
}


const delete_user = (userId, lang) => {

    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/users/deleteUser`, {
            userId: userId,
            lang: lang,
        })
            .then(res => {
                resolve(res)
            })
            .catch(() => {
                resolve(false);
            })
    })
}

const get_user_images = (userId) => {

    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/getImages?userId=${userId}`)
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const has_access_to_tool = () => {

    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/hasToolAccess`)
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_my_subscriptions = (userId) => {

    return new Promise((resolve, reject) => {
        axios.get(`${config.ERISED_BACKEND_URL}/subscriptions/getMySubscriptions?userId=${userId}`)
        .then(res => resolve(res))
        .catch(err=> resolve(false))
    })
}

const get_subscription = (userId) => {
    return new Promise((resolve, reject) => {
        axios.get(`${config.ERISED_BACKEND_URL}/subscriptions/getSubscription?userId=${userId}`)
        .then(res => resolve(res))
        .catch(err=> resolve(false))
    })
}

const save_preferences = (userId) => {
    return new Promise((resolve, reject) => {
        

        const theme = store.getState().theme;
        const language = store.getState().selectedLanguage;
        const region = store.getState().country;
        
        axios.post(`${config.ERISED_BACKEND_URL}/users/savePreferences`, {
            data: {
                userId: userId,
                theme: theme,
                language: language,
                region: region
            }
        })
        .then(res => {
            resolve(res)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const get_preferences = (userId) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/getPreferences?userId=${userId}`)
        .then(res => {
            resolve({
                theme: res?.data?.data?.theme ? res.data.data.theme : false,
                lang: res?.data?.data?.language ? res.data.data.language : false,
                region: res?.data?.data?.region ? res.data.data.region : false
            })
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const checkAccess = (planCategories) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/checkAccess?planCategories=${planCategories}`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const getCountryByIp = () => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/getCountryByIp`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const sendVerificationCode = (userId, email, lang) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/users/sendVerifyEmailCode`, {userId, email, lang})
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const reSendVerificationCode = (userId, email, lang) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/users/reSendVerifyEmailCode`, {userId, email, lang})
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const verifyEmailCode = (userId, code, email) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/users/verifyEmailCode`, {userId, code, email})
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}

const verifyOtpandChangeUsername = (userId, email, code, lang) => {
    return new Promise((resolve, reject) => {

        axios.post(`${config.ERISED_BACKEND_URL}/users/verifyOtpandChangeUsername`,{userId, code, email, lang})
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const validateUserByPhoneUsernameExist = (phoneNumber) => {
    return new Promise((resolve, reject) => {

        axios.get(`${config.ERISED_BACKEND_URL}/users/validateUserByPhoneUsernameExist?phoneNumber=${phoneNumber}`)
        .then(res => {
            resolve(res?.data?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


const adminDeleteUser = (userId) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.ERISED_BACKEND_URL}/admins/deleteUser`, {userId: userId})
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


export {
    get_users, 
    check_user_exists, 
    update_user,
    edit_settings, 
    get_settings,
    sort_users,
    activate_user,
    deactivate_user,
    delete_user,
    get_user_images,
    has_access_to_tool,
    get_my_subscriptions,
    save_preferences,
    get_preferences,
    checkAccess,
    getCountryByIp,
    get_subscription,
    sendVerificationCode,
    verifyEmailCode,
    get_user_by_username,
    verifyOtpandChangeUsername,
    validateUserByPhoneUsernameExist,
    reSendVerificationCode,
    adminDeleteUser,
};
