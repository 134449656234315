import { LangContext, Sidebar_, Sidebar_Box, logo } from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { config } from '../../config';
import i18n from '../../localization/i18next';
import { get_my_subscriptions } from '../../services/api/Users';
import store from '../../redux/store';
import { ThemeContext } from 'monica-alexandria';

const clients = require('../../config/index').config;

export default function Aside_(props) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = i18n.language ? i18n.language : 'en';
    const userId = store.getState().user?.user?.sub;
    const [mySubs, setMySubs] = useState([]);
    const { theme } = useContext(ThemeContext);
    const location = useLocation();



    const getAppImg = (app) => {
        if (app === 'Adsnap') return logo.lois[theme];
        else if ( app === 'Tailormade' ) return logo.lois[theme];  //TEMP LINE
        else if ( app === 'QuestAd' ) return logo.dailyprofit[theme]; //TEMP LINE
        else return 
    };

    const handleNav = (app) => {
        if (app === 'Adsnap') {
            window.location.assign(`${clients['lois']}/${lang}`);
        } else if ( app === 'QuestAd' ) {
            window.location.assign(`${clients['dailyprofit']}/${lang}`); //TEMP LINE
        } else if ( app === 'Tailormade' ) {
            window.location.assign(`${clients['lois']}/${lang}`); //TEMP LINE
        }
        else return;
    };

    useEffect(() => {
        get_my_subscriptions(userId)
            .then((data) => {
                if (!data?.data) setMySubs([]);
                else setMySubs(data?.data);
            })
            .catch((err) => {});
    }, []);


    return (
        <Sidebar_>
            <Sidebar_Box
                text={t('Home')}
                img={logo.all[theme]}
                onClick={() => navigate(`/${lang}/`)}
                active={location.pathname === `/${lang}/`}
            />
            <Sidebar_Box
                text={t('Subscriptions')}
                img={logo.griphook[theme]}
                onClick={() =>
                    window.location.assign(
                        `${clients['GRIPHOOK_FRONTEND_URL']}/${lang}/my-plans`
                    )
                }
            />
            {props?.activeSubs?.map((sub) => (
                <Sidebar_Box
                    text={t(sub.text_name)}
                    img={getAppImg(sub.text_name)}
                    onClick={() => handleNav(sub.text_name)}
                />
            ))}
            <Sidebar_Box
                text={t('Market')}
                img={logo.whitecastle[theme]}
                onClick={() =>
                    window.location.assign(`${clients['market']}/${lang}`)
                }
            />
            <Sidebar_Box
                text={t('Settings')}
                img={logo.erised[theme]}
                onClick={() => navigate(`/${lang}/settings`)}
                active={location.pathname.startsWith(`/${lang}/settings`)}
            />
            {props?.inactiveSubs?.map((sub) => (
                <Sidebar_Box
                    text={t(sub.text_name)}
                    img={getAppImg(sub.text_name)}
                    onClick={() => handleNav(sub.text_name)}
                    locked
                />
            ))}
        </Sidebar_>
    );
}
