import {
    Builder_,
    CardsSixty,
    Confirmation_,
    Form,
    Dates_,
    Grey_,
    Grey_Link,
    Main_,
    On_Click_Card,
    PageAlwaysVisible,
    PageDesktopOnly,
    PageTitle,
    PageToolsSixty,
    Red_,
    ThemeContext,
    PageSixty,
} from 'monica-alexandria';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Header_ from '../../components/Header_';
import Aside_ from '../../components/navigation/Aside_';
import { SecurityActions } from './SECURITY.css';
import store from '../../redux/store';
import i18n from '../../localization/i18next';
import { AuthContext } from '../../services/authentication/Guard';
import {
    activate_user,
    deactivate_user,
    delete_user,
} from '../../services/api/Users';
import { Helmet } from 'react-helmet';


export default function SECURITY(props) {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = store.getState().selectedLanguage;
    const auth = useContext(AuthContext);
    const user = store.getState().user
    const [confirm, setConfirm] = useState(false);
    const {theme} = useContext(ThemeContext)
    const [userInfo, setUserInfo] = useState({
        userId: store.getState().user?.user?.sub,
        active: auth.user?.active,
    });

    const handleConfirm = (action) => {
        if (action === 'deactivate') {
            handleActiveStatus(auth.user?.status);
        } else if (action === 'delete') {
            deleteUser();
        }
        setConfirm(false);
    };

    const handleActiveStatus = () => {
        if (userInfo.hasOwnProperty('active') && userInfo.active) {
            deactivate_user(userInfo.userId)
                .then((res) => {
                    setUserInfo({ ...userInfo, active: false });
                    store.dispatch({type: 'USER_UPDATE', payload: {...user, user: {
                        ...user.user, active: false
                    }}})
                })
                .catch((err) => {});
        } else {
            activate_user(userInfo.userId)
                .then((res) => {
                    setUserInfo({ ...userInfo, active: true });
                    store.dispatch({type: 'USER_UPDATE', payload: {...user, user: {
                        ...user.user, active: true
                    }}})
                })
                .catch((err) => {});
        }
        //setConfirm(false)
    };

    const showActiveStatus = () => {
        if (userInfo.hasOwnProperty('active') && userInfo.active) return (t('Deactivate'));
        else return (t('Activate'));
    };

    const deleteUser = () => {
        delete_user(userInfo.userId, lang)
            .then((res) => {})
            .catch((err) => {});
    };

    const handleConfirmMsg = () => {
        if (confirm === 'deactivate') {
            return (t('Are you sure you want to deactivate your account?'));
        }
        else if (confirm === 'activate') {
            return (t('Are you sure you want to activate your account?'));
        }
        else if (confirm === 'delete') {
            return (t('Are you sure you want to delete your account?'));
        }
    }

    return (
        <PageSixty>
            <Helmet>
                <title>Doitforme | User security</title>
            </Helmet>
            {confirm && (
                <Confirmation_
                    message={handleConfirmMsg()}
                    no={t("No")}
                    onNo={() => handleConfirm()}
                    onYes={() => handleConfirm(confirm)}
                    yes={t("Yes")}
                />
            )}
            <PageAlwaysVisible>
                <PageTitle>
                    <Grey_Link
                        iconLeft="Back"
                        noPadding
                        onClick={() => navigate(`/${lang}/settings`)}
                    />
                    <h1>{t('Privacy and security')}</h1>
                </PageTitle>
                <Header_ />
                <CardsSixty>
                    <On_Click_Card
                        onClick={() => auth.generate_password_link(theme)}
                        title={t('Update')}
                        icon="Lock"
                        subtitle={t('Password')}
                    />
                    <On_Click_Card
                        onClick={() => setConfirm('deactivate')}
                        title={showActiveStatus()}
                        icon="User"
                        subtitle={t('Account')}
                    />
                    <On_Click_Card
                        onClick={() => setConfirm('delete')}
                        title={t('Delete')}
                        icon="User"
                        subtitle={t('Account')}
                    />
                </CardsSixty>
            </PageAlwaysVisible>
            <PageDesktopOnly></PageDesktopOnly>
        </PageSixty>
    );
}
