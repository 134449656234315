import { Single_ } from 'monica-alexandria'
import React from 'react'
import i18n from '../../localization/i18next';
import { useTranslation } from 'react-i18next';


export default function NOT_FOUND() {

  const lang = i18n.language ? i18n.language : 'en';

  const { t } = useTranslation();
  
  const backToAccount = () => {
     window.location.assign(`/${lang}`)
  }

  return ( 
        <Single_
        backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/PageNotFound.svg"
        button={t("Back to your account")}
        subtitle={t("Page not found")}
        text={t("We cannot find the page you are looking for")}
        title={t("Oops")}
        onClickMain={backToAccount}
        />
  )
}
