import React from "react";
// import {
//   ValidatorError,
//   ValidatorRequired,
//   Validators,
//   ValidatorSuccess,
//   ValidatorTip,
//   ValidatorWarning,
// } from "monica-alexandria";
import { FormGroup, FormInput, FormLabel, FormList } from "monica-alexandria";
import { IconSvg } from "monica-alexandria";
import { Delete_Plain, Main_ } from "monica-alexandria";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Validators = styled.div``;

const Validator = styled.div`
  display: grid;
  grid-template-columns: var(--smallIcon) 1fr;
  align-items: flex-start;
  grid-gap: 1rem;
  padding: 0.5rem 1.6rem;

  svg {
    width: var(--smallIcon);
  }
`;

export const ValidatorTip = styled(Validator)`
  h6 {
    color: var(--greyDark) !important;
  }

  svg {
    fill: var(--greyDark) !important;
  }
`;

export const ValidatorSuccess = styled(Validator)`
  h6 {
    color: var(--success) !important;
  }

  svg {
    fill: var(--success) !important;
  }
`;

export const ValidatorWarning = styled(Validator)`
  h6 {
    color: var(--warning) !important;
  }

  svg {
    fill: var(--warning) !important;
  }
`;

export const ValidatorError = styled(Validator)`
  h6 {
    color: var(--error) !important;
  }

  svg {
    fill: var(--error) !important;
  }
`;

export const ValidatorRequired = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;

  svg {
    width: var(--smallIcon);
    fill: var(--warning);
  }
`;

const AccountEmail= styled.div`
    display: flex;
    gap: .5rem;
    align-items: center;
    transition: opacity .5s;

    h6{
        color: ${p => p.status ? "var(--success)" : "var(--warning)"};

        span{
            color: var(--main) !important;
            cursor: pointer;

            &:hover{
                opacity: ${p => p.status ? "1" : ".5"};
                transition: opacity .5s;
            }
        }
    }

    svg{
        fill: ${p => p.status ? "var(--success)" : "var(--warning)"} !important;
        width: 1.2rem !important;
        margin-bottom: 2px;
    }
`


export const Input_Email = (props) => {
  console.log('PROPS LIST', props.list);
  const {t} = useTranslation();
  return (
    <FormGroup className="FormGroup">
      <FormInput
        value={props.value}
        className={props.className + " " + "FormInput"}
        placeholder={props.placeholder}
        id={props.id}
        autoComplete="off"
        type="text"
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        onKeyPress={props.onKeyPress}
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
        ref={props.ref}
      />
      <FormLabel className="FormLabel">{props.placeholder}</FormLabel>
      {props.list ? (
        <Main_
          size="small"
          text={props.add}
          iconLeft="Add"
          className="FormAddButton"
          onClick={props.onAdd}
        />
      ) : null}
      {props.required ? (
        <ValidatorRequired className="ValidatorRequired">
          <IconSvg Icon="Asterisk" />
        </ValidatorRequired>
      ) : null}
      {props.errors ? (
        <Validators className="ValidatorErrors">
          {props.errors?.map((error, i) => (
            <ValidatorError key={i}>
              <IconSvg Icon="Error" />
              <h6>{error}</h6>
            </ValidatorError>
          ))}
        </Validators>
      ) : null}
      {props.successes ? (
        <Validators className="ValidatorSuccesses">
          {props.successes?.map((success, i) => (
            <ValidatorSuccess key={i}>
              <IconSvg Icon="Check" />
              <h6>{success}</h6>
            </ValidatorSuccess>
          ))}
        </Validators>
      ) : null}
      {props.helpers ? (
        <Validators className="ValidatorHelpers">
          {props.helpers?.map((helper, i) => (
            <ValidatorWarning key={i}>
              <IconSvg Icon="Warning" />
              <h6>{helper}</h6>
            </ValidatorWarning>
          ))}
        </Validators>
      ) : null}
      {props.tips ? (
        <Validators className="ValidatorTips">
          {props.tips?.map((tip, i) => (
            <ValidatorTip key={i}>
              <IconSvg Icon="Tip" />
              <h6>{tip}</h6>
            </ValidatorTip>
          ))}
        </Validators>
      ) : null}
      {props.list ? (
        <FormList>
          {props.defaultListItem ? (
            <li>
              <IconSvg Icon={props.icon_ListItem} />
              <h6>{props.defaultListItem}</h6>
              {props.deleteDefaultListItem ? (
                <Delete_Plain
                  onClick={props.onDeleteDefault}
                  size="small"
                  iconLeft="Delete"
                />
              ) : null}
            </li>
          ) : null}

          {props.list?.map((item, i) => (
            <li key={i}>
              <IconSvg Icon={props.icon_ListItem} />
              <div>
                <h6>{item?.email}</h6>
                <div onClick={() => props.onVerify(item?.email)}>
                    {/* {props.childrenItem} */}
                  {item?.verified ?
                    <AccountEmail status={true}>
                        <IconSvg Icon="Check" />
                        <h6>{t("verified")}</h6>
                    </AccountEmail>
                    :
                    <AccountEmail status="">
                        <IconSvg Icon="Error" />
                        <h6>{t("Email not verified. ")}
                        <span>{t("Verify")}</span></h6>
                    </AccountEmail>
                  }
                </div>   
              </div>
              <Delete_Plain
                onClick={() => props.onDelete(i)}
                size="small"
                iconLeft="Delete"
              />
            </li>
          ))}
        </FormList>
      ) : null}
    </FormGroup>
  );
};
