import { Single_ } from 'monica-alexandria'
import React from 'react'
import i18n from '../../localization/i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

export default function SERVICE_UNAVAILABLE() {

  const lang = i18n.language ? i18n.language : 'en';

  const { t } = useTranslation();
  
  const backToAccount = () => {
     window.location.assign(`/${lang}`)
  }

  const serviceName = () => {
    
    return (process.env.REACT_APP_SERVER == 'PRODUCTION') ? '' : ('"'+process.env.REACT_APP_CLIENT.toUpperCase()+' BACKEND"')
  }

  return (
   
    <>
      <Helmet>
          <title>Doitforme | Service unavailable</title>
      </Helmet>
      <Single_
        backgroundImage="https://genius1071.friktoriaservers.net/doitforme/morning/app/boiler/PageNotFound.svg"
        iconMain="User"
        onClickMain= {backToAccount}
        text={`${t('The service')} ${serviceName()} ${t('is temporary down, please try again later')}.`}
        textMain={t('Back to my account')}
        title={t('Service Unavailable')}
    />
    </>
  )
}
