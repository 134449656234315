import {
    PageAlwaysVisible,
    PageDesktopOnly,
    PageToolsSixty,
    AlertContext,
    Alert_,
    CardsTools,
    On_Click_Card,
    PageTitle,
    Builder_,
    Google_Search,
    Main_,
    PageFull,
    Filters_,
    TableMobile,
    TableDesktop,
    THead,
    TH,
    TBody,
    TR,
    TRH,
    TD,
    Main_Plain,
    Grey_Link,
} from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import store from '../../../redux/store';
import { getTickets } from '../../../services/api/Tickets';



export default function REQUESTS(props) {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const userId = store.getState().user?.user?.sub;
    const lang = store.getState().selectedLanguage;
    const { alerts, setAlerts } = useContext(AlertContext);

    const [tickets, setTickets] = useState([]);
    const [displayed, setDisplayed] = useState([]);
    const [searchResults, setSearchResults] = useState([]);

    const alertMessage = (message, status) => {
        setAlerts([
            ...alerts,
            {
                message: {
                    text: message,
                    status: status,
                },
            },
        ]);
    };

    const handleTicket = (ticket) => {
        if ( ticket?.action === 'DELETE' ) navigate(`/${lang}/akihiko/erised/users/${ticket?.user?.userId}`)
        else ( alertMessage(t('Managing actions other that DELETE is still under development'), 'Error'))
    }

    useEffect(() => {
        setDisplayed(tickets)
    },[tickets])

    useEffect(() => {
        if ( searchResults.input ) setDisplayed(searchResults.data) 
        else setDisplayed(tickets);
    },[searchResults])

    useEffect(() => {
        const fetchTickets = () => {
            getTickets().then(res=>setTickets(res?.data?.tickets)).catch(err=>{});    
        }
        fetchTickets()
    },[])

    return (
        <PageFull>
            <Alert_
                messages={alerts}
            />
            <PageTitle>
                <Grey_Link iconLeft="Back" noPadding onClick = {()=> navigate(-1)} />
                <h1>{t('Ticket Requests')}</h1>
            </PageTitle>
            <Filters_ 
                search = {
                    <Google_Search
                    placeholder={t('Type in a username or a name')}
                    file={tickets}
                    fileKeys={['user.username', 'user.firstname', 'user.lastname']}
                    sanitize={false}
                    setResults={(result) => setSearchResults(result)}
                    debounceTime={750}
                    isCaseSensitive={false}
                    maxAcceptedScore={0.6}
                    minMatchCharacters={0}
                    />
                }
            />
            <TableDesktop>
                <THead>
                    <TRH>
                        <TH></TH>
                        <TH>{t("Status")}</TH>
                        <TH>{t("First name")}</TH>
                        <TH>{t("Last name")}</TH>
                        <TH>{t("Action")}</TH>
                        <TH>{t("Created")}</TH>
                    </TRH>
                </THead>
                <TBody>
                    {displayed?.map((ticket,key)=> {
                        <TR key={key}>
                            <TD>
                                <Main_Plain iconLeft="Edit" onClick={()=>handleTicket(ticket)} />
                            </TD>
                            <TD>{ticket.status}</TD>
                            <TD>{ticket?.user?.firstname}</TD>
                            <TD>{ticket?.user?.lastname}</TD>
                            <TD>{ticket.action}</TD>
                            <TD>{new Date(ticket.createdAt).toLocaleDateString()}</TD>
                        </TR>
                    })}
                </TBody>
            </TableDesktop>
        </PageFull>
    );
}
